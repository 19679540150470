import { ASSETS_BULK_URL } from "../stores/assetsBulkStore";


export const markAssetsAsExportedAsync = async (
    assetIds:  number[]
) => {
    var jsonString = JSON.stringify(assetIds);
    return await fetch(ASSETS_BULK_URL + "markasexported", {
        method: "POST",
        body: jsonString,
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((result) => {
        if (result.status >= 200 && result.status < 300) {
            // @ts-ignore 
            window.location.reload(true);
        }
        return result;
    })
    .catch((error) => {
        alert("Exported Time Update Failed");
        console.error("Error:", error);
    });
}