import { Asset } from "../types/asset";

type FilterEqualityRule = "=" | "startswith";

type FilterChainRule = "and" | "or";

const manufacturerValueExpr: keyof Asset = "assetManufacturerName";
const subTypeValueExpr: keyof Asset = "assetSubTypeName";
const descriptionValueExpr: keyof Asset = "description"
const modelNameValueExpr: keyof Asset = "assetModelName";


type FilterField =
    | typeof manufacturerValueExpr
    | typeof subTypeValueExpr
    | typeof modelNameValueExpr
    | typeof descriptionValueExpr

type AssetFilter<K extends FilterField> = [FilterField, FilterEqualityRule, Asset[K]];

type FilterCollection = FilterChainRule | AssetFilter<FilterField>;

const addFilter = <K extends FilterField>(
    currentFilters: FilterCollection[],
    field: K,
    rule: FilterEqualityRule,
    value?: Asset[K]
) => {
    if (value) {
        if (currentFilters.length > 0) {
            currentFilters.push("and");
        }
        currentFilters.push([field, rule, value]);
    }
};

/*
  The purpose of this method is to create conditional filters for assets.
  Currently the only allowable filtering is on the fields listed in the 
  type FilterField above.
*/
export const getUpdatedAssetFilterValue = (
    manufacturerValue?: Asset[typeof manufacturerValueExpr],
    subTypeValue?: Asset[typeof subTypeValueExpr],
    descriptionValue?: Asset[typeof subTypeValueExpr],
    modelNameValue?: Asset[typeof modelNameValueExpr],
) => {
    let filter: FilterCollection[] = [];

    addFilter(filter, manufacturerValueExpr, "=", manufacturerValue);
    addFilter(filter, subTypeValueExpr, "=", subTypeValue);
    addFilter(filter, descriptionValueExpr, "=", descriptionValue);
    addFilter(filter, modelNameValueExpr, "=", modelNameValue);

    return filter.length > 0 ? filter : null;
};
