import DataGrid, {
    Column,
    Editing,
    HeaderFilter,
    Pager,
    SearchPanel
} from "devextreme-react/data-grid";
import React, { useEffect } from "react";

import { Button, Popup, Template } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import { useRef, useState } from "react";
import { FORMAT } from "../constants/format";
import { INFLATION_DATA_FIELDS } from "../constants/inflationDataFields";
import { inflationStore } from "../stores/inflationStore";
import { ToolbarPreparingEventingInternal } from "../types/toolbarPreparingEventInternal";
import { BaseInflationEditor } from "./BaseInflationEditor";

const allowedPageSizes = [10, 20, 50, 100];

const INFLATION_EDITOR_TEMPLATE_NAME = "inflationEditor";

export const InflationGrid = () => {
    const [inflationExists, setInflationExists] = useState(true);
    const [popupIsVisible, setPopupIsVisible] = useState(false);
    const dataGridRef = useRef<DataGrid>(null);

    //The below method ensures that the inflation grid loads each time the user visits the inflation screen.
    useEffect(()=>{
        gridDataSource.reload()
    },[])

    const editorOptions = { format: FORMAT.PERCENT.SINGLE_DECIMAL };

    const isEditable = (rowIndex: number) => {
        return rowIndex && rowIndex > 2;
    };

    const allowEditing = (e: { row: { rowIndex: number } }) => {
        return !isEditable(e.row.rowIndex);
    };

    const refreshDataGrid = () => {
        const dataGridInstance = dataGridRef.current?.instance;
        if (dataGridInstance) {
            dataGridInstance.refresh();
        }
    };

    const showPopup = () => {
        setPopupIsVisible(true);
    };

    const closePopup = () => {
        setPopupIsVisible(false);
    };

    const onToolbarPreparing = (e: ToolbarPreparingEventingInternal) => {
        e.toolbarOptions?.items?.unshift({
            location: "after",
            widget: "dxButton",
            options: {
                text: "Edit Base Inflation",
                onClick: showPopup,
            },
        });
    };

    const renderInflationEditor = () => (
        <Button hint="Edit Base Inflation" icon="percent" onClick={showPopup} />
    );

    const onCountentReady = (e: {
        component?: { getDataSource: () => DataSource };
    }) => {
        const dataSource = e.component?.getDataSource();
        const totalCount = dataSource?.totalCount();
        setInflationExists(!!totalCount);
    };

    const inflationDoesNotExist = !inflationExists;

    return (
        <div className="p-2">
            {popupIsVisible && (
                <Popup
                    visible={popupIsVisible}
                    width="auto"
                    height={"auto"}
                    showCloseButton={true}
                    onHiding={closePopup}
                >
                    <BaseInflationEditor
                        onAfterSaveSuccess={refreshDataGrid}
                        showGenerateButton={inflationDoesNotExist}
                    />
                </Popup>
            )}
            {inflationDoesNotExist && (
                <div className="d-flex align-items-center">
                    <div className="w-100 text-info p-2">
                        No Projection Years & Inflation exist on this project.
                        Enter a base inflation rate to generate the table.
                    </div>
                </div>
            )}
            <DataGrid
                ref={dataGridRef}
                dataSource={gridDataSource}
                showBorders={true}
                columnAutoWidth={true}
                width={"100%"}
                height={"auto"}
                renderAsync={true}
                onToolbarPreparing={onToolbarPreparing}
                onContentReady={onCountentReady}
            >
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} Inflation)"
                    visible={true}
                />
                <Editing mode="form" allowUpdating={allowEditing} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />

                <Column
                    dataField={INFLATION_DATA_FIELDS.ID}
                    caption="Id"
                    visible={false}
                    allowEditing={false}
                />
                <Column dataField={INFLATION_DATA_FIELDS.PROJECT_YEAR} allowEditing={false} />
                <Column dataField={INFLATION_DATA_FIELDS.PERIOD} allowEditing={false} />
                <Column dataField={INFLATION_DATA_FIELDS.FISCAL_YEAR} allowEditing={false} />
                <Column
                    dataField={INFLATION_DATA_FIELDS.YEARLY_INFLATION}
                    allowEditing={true}
                    dataType="number"
                    editorOptions={editorOptions}
                    format={FORMAT.PERCENT.SINGLE_DECIMAL}
                />
                <Column
                    dataField={INFLATION_DATA_FIELDS.CUMULATIVE_INFLATION}
                    allowEditing={false}
                    dataType="number"
                    editorOptions={editorOptions}
                    format={FORMAT.PERCENT.SINGLE_DECIMAL}
                />
                <Template
                    name={INFLATION_EDITOR_TEMPLATE_NAME}
                    render={renderInflationEditor}
                />
            </DataGrid>
        </div>
    );
};

const gridDataSource = new DataSource({ store: inflationStore });
