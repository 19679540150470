import { Inflation } from "../types/inflation";
import { createDataFields } from "../utility functions/createDataFields";

export const INFLATION_DATA_FIELDS = createDataFields<Inflation>()({
    ID: "id",
    STUDY_ID: "studyId",
    PROJECT_YEAR: "projectYear",
    PERIOD: "period",
    FISCAL_YEAR: "fiscalYear",
    YEARLY_INFLATION: "yearlyInflation",
    CUMULATIVE_INFLATION: "cumulativeInflation",
});
