import SelectBox, { ISelectBoxOptions } from "devextreme-react/select-box";

import DataSource from "devextreme/data/data_source";
import React, { useRef } from "react";
import { studyStore } from "../stores/studyStore";
import { OmitStrict } from "../types/omitStrict";
import { Study } from "../types/study";

type StudySelectProps = OmitStrict<
    ISelectBoxOptions,
    "dataSource" | "displayExpr" | "valueExpr"
>;

export const StudySelect = ({
    placeholder = "Choose Existing Study...",
    searchEnabled = true,
    showClearButton = true,
    ...props
}: StudySelectProps) => {
    const selectBox = useRef<SelectBox>(null)

    const valueExpr: keyof Study = "id";
    const displayExpr: keyof Study = "clubName";  

    const onOptionChanged = (e: { name?: string; value?: boolean }) => {
        /*The below codeblock needed to be added in order to re-populate 
        the selection items after the clear button was clicked.*/
        if (e.name === "isActive" && e.value === false) {
            const dataSource = selectBox.current?.instance.getDataSource();
            if (dataSource) {
                dataSource.load();
            }
        }
        if(props.onOptionChanged){
            props.onOptionChanged(e)
        }
    };

    return (
        <SelectBox
            {...props}
            ref={selectBox}
            searchEnabled={searchEnabled}
            showClearButton={showClearButton}
            placeholder={placeholder}
            valueExpr={valueExpr}
            displayExpr={displayExpr}
            dataSource={dataSource}
            onOptionChanged={onOptionChanged}
        />
    );
};

const dataSource = new DataSource({
    store: studyStore
})
