import React, { useCallback, useRef, useState } from "react";

import { CheckBox } from "devextreme-react";
import DataGrid, {
    Column,
    ColumnFixing,
    Editing,
    Form,
    FormItem,
    Grouping,
    HeaderFilter,
    IFormItemProps,
    Lookup,
    Pager,
    Paging,
    Popup,
    RangeRule,
    RequiredRule,
    SearchPanel,
} from "devextreme-react/data-grid";
import { GroupItem, Item, SimpleItem } from "devextreme-react/form";
import { generateNewAssetIdAsync } from "../api/generateNewAssetIdAsync";
import { ASSET_DATA_FIELDS } from "../constants/assetDataFIelds";
import { FORMAT } from "../constants/format";
import { LEASE_TERM_PARAMS } from "../constants/leaseTermParams";
import { assetCodingClassStore } from "../stores/assetCodingClassStore";
import { assetEntryServiceLifeStore } from "../stores/assetEntryServiceLivesStore";
import { assetStore } from "../stores/assetStore";
import { assetTypeStore } from "../stores/assetTypeStore";
import { studyRoomStore } from "../stores/studyRoomStore";
import { studyStructureStore } from "../stores/studyStructureStore";
import { unitOfMeasureStore } from "../stores/unitOfMeasureStore";
import { EditorPreparingEventInternal } from "../types/editorPreparingEventInternal";
import { NullableAsset } from "../types/nullableAsset";
import { calculateAggregateReplacementValue } from "../utility functions/calculateAggregateReplacementValue";
import { calculateLeaseEndValue } from "../utility functions/calculateLeaseEndValue";
import { calculateLeaseTotalPayment } from "../utility functions/calculateLeaseTotalPayment";
import { calculateLeaseYearlyPayment } from "../utility functions/calculateLeaseYearlyPayment";
import { DollarBuyoutInfo } from "./DollarBuyoutInfo";

const allowedPageSizes = [10, 20, 50, 100];
const defaultPageSize = 10;

const NEW_LEASE_FORM_ITEMS = "newLeaseFormItems";
const LEGACY_LEASE_INFO_TEXT_ITEM = "legacyLeaseInfoText";
const GROUP_FORM_ITEM_TYPE = "group";

const ONE_CENT = 0.01;

const thousandsAndDecimalEditorOptions = {
    format: FORMAT.THOUSANDS_AND_DECIMAL,
};
const wholeNumberEditorOptions = { format: FORMAT.WHOLE_NUMBER };

const DOLLAR_BUYOUT_TOTAL_COLUMNS = 40;
const DOLLAR_BUYOUT_ICON_COLUMN_SPAN = 1;

interface AssetCodingClassEditorState {
    initialAssetIdValue: string | null | undefined
    initialCodingClassIdValue: number | null | undefined
};

const INITIAL_ASSET_ID_EDITOR_STATE: AssetCodingClassEditorState = {
    initialAssetIdValue: null,
    initialCodingClassIdValue: null,
};

const AssetSummaryDrillDown = () => {
    const dataGrid = useRef<DataGrid>(null);
    const [autoExpandAll, setAutoExpandAll] = useState(false);
    const [assetIdEditorState, setAssetIdEditorState] =
        useState<AssetCodingClassEditorState>(INITIAL_ASSET_ID_EDITOR_STATE);
    // const [assetIdIsLoading, setAssetIdIsLoading] = useState(false);
    // const [assetIdValue, setAssetIdValue] = useState<string | null | undefined>(
    //     null
    // );

    const onAutoExpandAllChanged = () => {
        setAutoExpandAll((isExpanded) => !isExpanded);
    };

    const handleAssetCodingClassChangeAsync = async (
        newValue: number,
        id: number | null | undefined
    ) =>
        await generateNewAssetIdAsync({
            assetCodingClassId: newValue,
            idOfExistingAsset: id,
        })

    const onEditorPreparing = async (
        e: EditorPreparingEventInternal<NullableAsset>
    ) => {
        const rowIndex = e.row?.rowIndex;
        if (e.parentType === "dataRow") {
            if (
                e.dataField === ASSET_DATA_FIELDS.STUDY_ROOM_ID &&
                e.editorOptions
            ) {
                const isStructureNotSet =
                    e.row?.data?.studyStructureId === undefined;

                e.editorOptions.disabled = isStructureNotSet;
            }

            if (
                e.dataField === ASSET_DATA_FIELDS.ASSET_CODING_CLASS &&
                e.editorOptions
            ) {
                const id = e.row?.data?.id;
                const defaultValueChangeHandler =
                    e.editorOptions.onValueChanged;

                e.editorOptions.onValueChanged = async function (e: {
                    value: number | null | undefined;
                }) {
                    defaultValueChangeHandler(e);
                    const newValue = e.value;
                    let newAssetIdValue: string | null | undefined;
                    if (!newValue) {
                        newAssetIdValue = null;
                    } else {
                        if (
                            newValue ===
                            assetIdEditorState.initialCodingClassIdValue
                        ) {
                            newAssetIdValue = assetIdEditorState.initialAssetIdValue
                        } else {
                            const generatedAssetId =
                                await handleAssetCodingClassChangeAsync(
                                    newValue,
                                    id
                                );
                         
                            newAssetIdValue = generatedAssetId || assetIdEditorState.initialAssetIdValue
                        }
                    }

                    dataGrid.current?.instance.cellValue(
                        rowIndex || 0,
                        ASSET_DATA_FIELDS.ASSET_ID,
                        newAssetIdValue
                    );
                };
            }
        }
    };

    const getFilteredRooms = (options: { data: NullableAsset }) => {
        return {
            store: studyRoomStore,
            filter: options.data
                ? [
                      ASSET_DATA_FIELDS.STUDY_STRUCTURE_ID,
                      "=",
                      options.data.studyStructureId,
                  ]
                : null,
        };
    };

    const setStructureValue = (rowData: NullableAsset, value: number) => {
        rowData.studyRoomId = null;
        rowData.studyStructureId = value;
    };

    const setUnitReplacmentCellValue = (
        rowData: NullableAsset,
        value: number,
        currentData: NullableAsset
    ) => {
        rowData.aggregateReplacementValue = calculateAggregateReplacementValue(
            currentData.qty,
            value
        );
        rowData.unitReplacementValue = value;
    };

    const setQtyCellValue = (
        rowData: NullableAsset,
        value: number,
        currentData: NullableAsset
    ) => {
        rowData.aggregateReplacementValue = calculateAggregateReplacementValue(
            value,
            currentData.unitReplacementValue
        );
        rowData.qty = value;
    };

    const setLeaseStatusValue = (newData: NullableAsset, value: boolean) => {
        newData.isLeased = value;
    };

    const isNewLeasedFormItem = (item: IFormItemProps): boolean => {
        return (
            item &&
            item.itemType === GROUP_FORM_ITEM_TYPE &&
            item.name === NEW_LEASE_FORM_ITEMS
        );
    };

    const isLegacyLeasedOrStandardFormItem = (
        item: IFormItemProps
    ): boolean => {
        return (
            item &&
            (item.dataField === ASSET_DATA_FIELDS.YEAR_INSTALLED ||
                item.dataField === ASSET_DATA_FIELDS.INITIAL_RY_AUTO ||
                item.dataField === ASSET_DATA_FIELDS.INITIAL_RY_MANUAL ||
                item.dataField === ASSET_DATA_FIELDS.LAST_RY_CYCLE ||
                item.dataField === ASSET_DATA_FIELDS.UNIT_REPLACEMENT_VALUE ||
                item.dataField ===
                    ASSET_DATA_FIELDS.AGGREGATE_REPLACEMENT_VALUE)
        );
    };

    const isLegacyLeasedOnlyFormItem = (item: IFormItemProps): boolean =>
        item && item.name === LEGACY_LEASE_INFO_TEXT_ITEM;

    const customizeItem = useCallback((item: IFormItemProps) => {
        const newLeasedFormItem = isNewLeasedFormItem(item);
        const legacyLeasedOrStandardFormItem =
            isLegacyLeasedOrStandardFormItem(item);
        const legacyLeaseOnlyFormItem = isLegacyLeasedOnlyFormItem(item);
        if (
            newLeasedFormItem ||
            legacyLeasedOrStandardFormItem ||
            legacyLeaseOnlyFormItem
        ) {
            const dataGridInstance = dataGrid.current!.instance;
            const editing = dataGridInstance.option("editing");
            const rowIndex = dataGridInstance.getRowIndexByKey(
                editing?.editRowKey!
            );
            const isLeased = dataGridInstance.cellValue(
                rowIndex,
                ASSET_DATA_FIELDS.IS_LEASED
            );
            const isLegacyLease = dataGridInstance.cellValue(
                rowIndex,
                ASSET_DATA_FIELDS.IS_LEGACY_LEASE
            );
            item.visible =
                (isLegacyLease && isLeased && legacyLeaseOnlyFormItem) ||
                (newLeasedFormItem && isLeased && !isLegacyLease) ||
                (legacyLeasedOrStandardFormItem &&
                    (isLegacyLease || !isLeased));
        }
    }, []);

    const setLeaseStartCellValue = (
        rowData: NullableAsset,
        value: Date | null,
        currentData: NullableAsset
    ) => {
        rowData.leaseEnd = calculateLeaseEndValue(value, currentData.leaseTerm);
        rowData.leaseStart = value;
    };

    const setLeaseTermCellValue = (
        rowData: NullableAsset,
        value: number | null,
        currentData: NullableAsset
    ) => {
        rowData.leaseEnd = calculateLeaseEndValue(
            currentData.leaseStart,
            value
        );
        rowData.leaseTotalPayment = calculateLeaseTotalPayment(
            currentData.leaseMonthlyPayment,
            value
        );
        rowData.leaseTerm = value || null; //Do not allow zero but default to null.
    };

    const setLeaseMonthlyPaymentCellValue = (
        rowData: NullableAsset,
        value: number | null,
        currentData: NullableAsset
    ) => {
        rowData.leaseTotalPayment = calculateLeaseTotalPayment(
            value,
            currentData.leaseTerm
        );
        rowData.leaseYearlyPayment = calculateLeaseYearlyPayment(value);
        rowData.leaseMonthlyPayment = value || null; //Do not allow zero but default to null.
    };

    function setDollarBuyoutCellValue(
        rowData: NullableAsset,
        value: number | null
    ) {
        rowData.leaseDollarBuyoutAmount = value || null; //Do not allow zero but default to null.
    }

    const onEditingStart = (e: { data?: NullableAsset }) => {
        setAssetIdEditorState({
            initialAssetIdValue: e.data?.assetId,
            initialCodingClassIdValue: e.data?.assetCodingClassId,
        });
    };

    const onEditCanceled = () => {
        setAssetIdEditorState(INITIAL_ASSET_ID_EDITOR_STATE);
    };


    // //If AssetID is modified in form.
    // const updateRow = (e: RowUpdatingEventInternal<NullableAsset, "id">) => {
    //     const { newData, oldData, key } = e;

    //     const isCanceled = new Promise((resolve, reject) => {
    //         const assedIdWillUpdate = oldData && newData && 
    //             Object.hasOwn(newData, ASSET_DATA_FIELDS.ASSET_ID) && key;

    //         if (!assedIdWillUpdate) {
    //             resolve(false);
    //         } else {
    //             e.component?.option("loadPanel.enabled", false);
    //             const promptPromise = confirm(
    //                 `The Asset Coding class was updated and the Asset ID will attempt to be updated from ${
    //                     oldData[ASSET_DATA_FIELDS.ASSET_ID]
    //                 } to ${newData[ASSET_DATA_FIELDS.ASSET_ID]}.`,
    //                 "Confirm changes"
    //             );
    //             promptPromise.then((dialogResult) => {
    //                 console.log("dialogResult: ", dialogResult);
    //                 if (dialogResult) {
    //                     return resolve(false);
    //                 } else {
    //                     return resolve(true);
    //                 }
    //             });
    //             e.component?.option("loadPanel.enabled", false);
    //         }
    //     });

    //     e.cancel = isCanceled;
    // }

    return (
        <>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                columnAutoWidth={true}
                onEditorPreparing={onEditorPreparing}
                onEditingStart={onEditingStart}
                onEditCanceled={onEditCanceled}
                // onRowUpdating={updateRow}
                ref={dataGrid}
            >
                <Pager
                    visible={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} Assets)"
                />
                <Paging defaultPageSize={defaultPageSize} />
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                >
                    <Popup
                        title="Asset Detail"
                        showTitle={true}
                        fullScreen={true}
                    />
                    <Form customizeItem={customizeItem}>
                        <Item name={LEGACY_LEASE_INFO_TEXT_ITEM} colSpan={2}>
                            <div className="text-info">
                                The asset being edited is a legacy lease asset
                                and the new lease form cannot be used.
                            </div>
                        </Item>
                        <SimpleItem dataField={ASSET_DATA_FIELDS.ID} />
                        <SimpleItem
                            dataField={
                                ASSET_DATA_FIELDS.STRUCTURE_LOCATION_NAME
                            }
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.ROOM_AREA_NAME}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.STUDY_ROOM_ID}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.ASSET_CODING_CLASS}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.ASSET_TYPE_ID}
                        />
                        <SimpleItem dataField={ASSET_DATA_FIELDS.IS_LEASED} />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.ASSET_SUB_TYPE_NAME}
                        />
                        <SimpleItem
                            dataField={
                                ASSET_DATA_FIELDS.ASSET_MANUFACTURER_NAME
                            }
                        />
                        <SimpleItem dataField={ASSET_DATA_FIELDS.DESCRIPTION} />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.ASSET_MODEL_NAME}
                        />
                        <SimpleItem dataField={ASSET_DATA_FIELDS.ASSET_ID} />
                        <SimpleItem dataField={ASSET_DATA_FIELDS.QTY} />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.UNIT_OF_MEASURE_ID}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.YEAR_INSTALLED}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.ASSET_SERVICE_LIFE_ID}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.ASSET_LIFE_CYCLE}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.UNIT_REPLACEMENT_VALUE}
                        />
                        <SimpleItem
                            dataField={
                                ASSET_DATA_FIELDS.AGGREGATE_REPLACEMENT_VALUE
                            }
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.INITIAL_RY_AUTO}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.INITIAL_RY_MANUAL}
                        />
                        <SimpleItem
                            dataField={ASSET_DATA_FIELDS.LAST_RY_CYCLE}
                        />
                        <SimpleItem dataField={ASSET_DATA_FIELDS.LEDGER_ID} />
                        <SimpleItem dataField={ASSET_DATA_FIELDS.NOTES} />
                        <GroupItem
                            name={NEW_LEASE_FORM_ITEMS}
                            colCount={2}
                            colSpan={2}
                            visible={false}
                        >
                            <SimpleItem
                                dataField={ASSET_DATA_FIELDS.LEASE_START}
                            />
                            <SimpleItem
                                dataField={ASSET_DATA_FIELDS.LEASE_TERM}
                            />
                            <SimpleItem
                                dataField={
                                    ASSET_DATA_FIELDS.LEASE_MONTHLY_PAYMENT
                                }
                            />
                            <GroupItem colCount={DOLLAR_BUYOUT_TOTAL_COLUMNS}>
                                <Item colSpan={DOLLAR_BUYOUT_ICON_COLUMN_SPAN}>
                                    <div
                                        style={{ height: 35 }}
                                        className="d-flex align-items-center"
                                    >
                                        <DollarBuyoutInfo
                                            toolTipPosition={"right"}
                                        />
                                    </div>
                                </Item>
                                <SimpleItem
                                    colSpan={
                                        DOLLAR_BUYOUT_TOTAL_COLUMNS -
                                        DOLLAR_BUYOUT_ICON_COLUMN_SPAN
                                    }
                                    dataField={
                                        ASSET_DATA_FIELDS.LEASE_DOLLAR_BUYOUT_AMOUNT
                                    }
                                />
                            </GroupItem>
                            <SimpleItem
                                dataField={ASSET_DATA_FIELDS.LEASE_END}
                            />
                            <SimpleItem
                                dataField={
                                    ASSET_DATA_FIELDS.LEASE_TOTAL_PAYMENT
                                }
                            />
                            <SimpleItem
                                dataField={
                                    ASSET_DATA_FIELDS.LEASE_YEARLY_PAYMENT
                                }
                            />
                        </GroupItem>
                    </Form>
                </Editing>

                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />

                <Grouping autoExpandAll={autoExpandAll} expandMode="rowClick" />

                <Column
                    dataField={ASSET_DATA_FIELDS.ID}
                    caption="Id"
                    allowEditing={false}
                    visible={false}
                />

                <Column
                    dataField={ASSET_DATA_FIELDS.STRUCTURE_LOCATION_NAME}
                    caption="Structure/Location Name"
                    groupIndex={0}
                    allowEditing={false}
                >
                    <FormItem visible={false} />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.STUDY_STRUCTURE_ID}
                    setCellValue={setStructureValue}
                    caption="Structure/Location"
                    visible={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={studyStructureStore}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.ROOM_AREA_NAME}
                    caption="Room"
                    groupIndex={1}
                    allowEditing={false}
                >
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.STUDY_ROOM_ID}
                    caption="Room/Area"
                    visible={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={getFilteredRooms}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.ASSET_CODING_CLASS}
                    caption="Asset Coding Class"
                    visible={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={assetCodingClassStore}
                        valueExpr="id"
                        displayExpr="codeName"
                        allowClearing={true}
                    />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.ASSET_TYPE_ID}
                    caption="Asset Type"
                    visible={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={assetTypeStore}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.IS_LEASED}
                    caption="Lease Status"
                    dataType="boolean"
                    setCellValue={setLeaseStatusValue}
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.ASSET_SUB_TYPE_NAME}
                    caption="Asset Sub-Type"
                >
                    <RequiredRule message="Asset Sub-Type is required." />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.ASSET_MANUFACTURER_NAME}
                    caption="Asset Manufacturer"
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.DESCRIPTION}
                    caption="Asset Detail"
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.ASSET_MODEL_NAME}
                    caption="Asset Model ID"
                />
                <Column
                    allowEditing={false}
                    dataField={ASSET_DATA_FIELDS.ASSET_ID}
                    caption="Asset Id"
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.QTY}
                    caption="Qty"
                    setCellValue={setQtyCellValue}
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.UNIT_OF_MEASURE_ID}
                    caption="UOM"
                    visible={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={unitOfMeasureStore}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.YEAR_INSTALLED}
                    dataType="number"
                    caption="Year Installed"
                    format={FORMAT.WHOLE_NUMBER}
                    editorOptions={wholeNumberEditorOptions}
                >
                    <RangeRule
                        min={0}
                        message="Year Installed needs to be a positive number."
                    />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.ASSET_SERVICE_LIFE_ID}
                    caption="Asset Service Life"
                    visible={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={assetEntryServiceLifeStore}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.ASSET_LIFE_CYCLE}
                    dataType="number"
                    caption="Asset Life Cycle"
                    format={FORMAT.WHOLE_NUMBER}
                    editorOptions={wholeNumberEditorOptions}
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.UNIT_REPLACEMENT_VALUE}
                    caption="Unit Replacement Value"
                    dataType="number"
                    format={FORMAT.THOUSANDS_AND_DECIMAL}
                    editorOptions={thousandsAndDecimalEditorOptions}
                    setCellValue={setUnitReplacmentCellValue}
                >
                    <RangeRule
                        min={0}
                        message="Unit Replacement Value needs to be a positive number."
                    />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.AGGREGATE_REPLACEMENT_VALUE}
                    caption="Aggregate Replacement Value"
                    dataType="number"
                    format={FORMAT.THOUSANDS_AND_DECIMAL}
                    editorOptions={thousandsAndDecimalEditorOptions}
                    allowEditing={false}
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.INITIAL_RY_AUTO}
                    dataType="number"
                    caption="Initial RY Auto"
                    format={FORMAT.WHOLE_NUMBER}
                    editorOptions={wholeNumberEditorOptions}
                >
                    <RangeRule
                        min={0}
                        message="Initial RY, Auto needs to be a positive number."
                    />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.INITIAL_RY_MANUAL}
                    dataType="number"
                    caption="Initial RY Manual"
                    format={FORMAT.WHOLE_NUMBER}
                    editorOptions={wholeNumberEditorOptions}
                >
                    <RangeRule
                        min={0}
                        message="Initial RY, Auto needs to be a positive number."
                    />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.LAST_RY_CYCLE}
                    dataType="number"
                    caption="Last RY Cycle"
                    format={FORMAT.WHOLE_NUMBER}
                    editorOptions={wholeNumberEditorOptions}
                >
                    <RangeRule
                        min={0}
                        message="Initial RY, Auto needs to be a positive number."
                    />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.LEDGER_ID}
                    caption="Ledger Id"
                />

                <Column dataField={ASSET_DATA_FIELDS.NOTES} caption="Notes">
                    <FormItem colSpan={4} editorType="dxTextArea" />
                </Column>

                <Column
                    dataField={ASSET_DATA_FIELDS.LEASE_START}
                    dataType="date"
                    caption="Current Lease Term Start"
                    setCellValue={setLeaseStartCellValue}
                >
                    <RequiredRule message="Current Lease Term Start is required." />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.LEASE_TERM}
                    dataType="number"
                    caption="Lease Term in Months"
                    format={FORMAT.WHOLE_NUMBER}
                    editorOptions={wholeNumberEditorOptions}
                    setCellValue={setLeaseTermCellValue}
                >
                    <RequiredRule message="Lease Term in Months is required." />
                    <RangeRule
                        min={LEASE_TERM_PARAMS.MIN}
                        max={LEASE_TERM_PARAMS.MAX}
                        message={`Lease Term needs to be between ${LEASE_TERM_PARAMS.MIN} and ${LEASE_TERM_PARAMS.MAX}.`}
                    />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.LEASE_MONTHLY_PAYMENT}
                    dataType="number"
                    caption="Current Monthly Payment"
                    format={FORMAT.THOUSANDS_AND_DECIMAL}
                    editorOptions={thousandsAndDecimalEditorOptions}
                    setCellValue={setLeaseMonthlyPaymentCellValue}
                >
                    <RequiredRule message="Current Monthly Payment is required." />
                    <RangeRule
                        min={ONE_CENT}
                        message={`Current Monthly Payment needs to be a positive number greater than ${ONE_CENT}.`}
                    />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.LEASE_DOLLAR_BUYOUT_AMOUNT}
                    dataType="number"
                    caption="Dollar Buyout Amount"
                    format={FORMAT.THOUSANDS_AND_DECIMAL}
                    editorOptions={thousandsAndDecimalEditorOptions}
                    setCellValue={setDollarBuyoutCellValue}
                >
                    <RangeRule
                        min={ONE_CENT}
                        message="Dollar Buyout Amount needs to be a positive number."
                    />
                </Column>
                <Column
                    dataField={ASSET_DATA_FIELDS.LEASE_END}
                    dataType="date"
                    allowEditing={false}
                    caption="Current Lease Term End"
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.LEASE_YEARLY_PAYMENT}
                    dataType="number"
                    allowEditing={false}
                    caption="Total Yearly Lease Payments"
                    format={FORMAT.THOUSANDS_AND_DECIMAL}
                    editorOptions={thousandsAndDecimalEditorOptions}
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.LEASE_TOTAL_PAYMENT}
                    dataType="number"
                    allowEditing={false}
                    caption="Total Lease Payments"
                    format={FORMAT.THOUSANDS_AND_DECIMAL}
                    editorOptions={thousandsAndDecimalEditorOptions}
                />
                <Column
                    dataField={ASSET_DATA_FIELDS.IS_LEGACY_LEASE}
                    dataType="boolean"
                    allowEditing={false}
                    visible={false}
                />
                <ColumnFixing enabled={true} />
            </DataGrid>
            <div className="options">
                <div className="option">
                    <CheckBox
                        text="Expand All Structures"
                        value={autoExpandAll}
                        onValueChanged={onAutoExpandAllChanged}
                    />
                </div>
            </div>
        </>
    );
};

const gridDataSource = {
    store: assetStore,
};

export default AssetSummaryDrillDown;
