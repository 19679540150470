import React from "react";

import DataGrid, {
    Column,
    Editing,
    HeaderFilter,
    Pager,
    PatternRule,
    RequiredRule,
    SearchPanel,
} from "devextreme-react/data-grid";

import { structureLocationTypeStore } from "../stores/structureLocationTypeStore";

const allowedPageSizes = [10, 20, 50, 100];

const StructureLocationType = () => {
    return (
        <DataGrid dataSource={gridDataSource} showBorders={true}>
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page {0} of {1} ({2} Structure Location Types)"
            />
            <Editing mode="form" allowUpdating={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} />
            <Column
                dataField="id"
                caption="Id"
                width={100}
                allowEditing={false}
            />
            <Column dataField="name">
                <RequiredRule message="Name is required" />
            </Column>
            <Column dataField="sortOrder" width={100}>
                <RequiredRule message="Sort Order is required" />
                <PatternRule
                    pattern="^[0-9]+$"
                    message="Sort Order must be a number"
                />
            </Column>
            <Column dataField="createdBy" width={170} allowEditing={false} />
            <Column dataField="dateModified" width={170} allowEditing={false} />
            <Column dataField="modifiedBy" width={170} allowEditing={false} />
        </DataGrid>
    );
};

const gridDataSource = {
    store: structureLocationTypeStore,
}

export default StructureLocationType;
