import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	Collapse,
	Container,
	NavItem,
	NavLink,
	Navbar,
	NavbarToggler
} from "reactstrap";
import "./NavMenu.css";
import { LoginMenu } from "./api-authorization/LoginMenu";

export const NavMenu = () => {
    const [currentStudyName, setCurrentStudyName] = useState("");
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        fetch("/api/studycontext/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((result) => {
                return result.json();
            })
            .then((json) => {
                var currentStudyName = json.name;
                setCurrentStudyName(currentStudyName);
            })
            .catch((e) => console.log("errors", e));
    }, []);

    const toggleNavbar = () => {
        setCollapsed((isCollapsed) => !isCollapsed);
    };

    return (
        <header>
            <Navbar
                className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
                light
            >
                <Container>
                    <div> {currentStudyName}</div>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse
                        className="d-sm-inline-flex flex-sm-row-reverse"
                        isOpen={!collapsed}
                        navbar
                    >
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    className="text-dark"
                                    to="/"
                                >
                                    Home
                                </NavLink>
                            </NavItem>
                            <LoginMenu></LoginMenu>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
};
