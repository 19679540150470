import React, { useState } from "react";

import Box, { Item } from "devextreme-react/box";
import Button from "devextreme-react/button";
import { LoadIndicator } from "devextreme-react/load-indicator";
import parse from "html-react-parser";
import { Container } from "reactstrap";
import { StudySelect } from "./StudySelect";

const PublishToCompass = () => {
    const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false);
    const [selectedStudyId, setSelectedStudyId] = useState<number>();
    const [publishResponse, setPublishResponse] = useState("");

    const onChosenStudyChanged = (e: { value?: number }) => {
        setSelectedStudyId(e.value);
    };

    const handleSubmit = () => {
        setLoadIndicatorVisible(true);
        fetch("/api/studies/publishtocompass/" + selectedStudyId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((result) => {
                result.json().then((e) => {
                    setPublishResponse(e.message);
                });
            })
            .catch((e) => {
                console.log("errors", e);
            })
            .finally(() => {
                setLoadIndicatorVisible(false);
            });
    };

    return (
        <Container>
            <Box direction="row" width="100%">
                <Item ratio={1}>
                    <h3>Publish Study To COMPASS</h3>
                    <p style={{ color: "red", width: "80%" }}>
                        Warning: After publishing to COMPASS, the study will be
                        locked for editing in the CRS Entry Tool. All further
                        additions and updates will need to be done through the
                        COMPASS tool.
                    </p>
                    <p>Choose an Existing Study to Publish to COMPASS:</p>
                    <StudySelect
                        onValueChanged={onChosenStudyChanged}
                        width={"75%"}
                    />
                    <Button
                        text="Publish Chosen Study"
                        useSubmitBehavior={false}
                        onClick={handleSubmit}
                        disabled={!selectedStudyId}
                    />
                </Item>
            </Box>

            <hr />
            <LoadIndicator
                className="button-indicator"
                visible={loadIndicatorVisible}
            />
            <div
                className="publishResults"
                style={{
                    display: publishResponse.length > 0 ? "block" : "none",
                }}
            >
                <div>
                    <h3>Publish Results</h3>
                    <div>{parse(publishResponse)}</div>
                </div>
            </div>
        </Container>
    );
};

export default PublishToCompass;
