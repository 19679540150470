import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "devextreme-react";
import { positionConfig } from "devextreme/animation/position";
import React from "react";

interface DollarBuyoutInfoProps {
    iconClassName?: string;
    toolTipPosition:
        | "top"
        | "left"
        | "right"
        | "bottom"
        | positionConfig
        | undefined;
    toolTipWidth?: number;
}

export const DollarBuyoutInfo = ({
    iconClassName,
    toolTipPosition,
    toolTipWidth = 400,
}: DollarBuyoutInfoProps) => {
    const renderToolTipContent = () => {
        return (
            <div style={{ width: "100%", whiteSpace: "normal" }}>
                If a value is entered for the Dollar Buyout Amount, the study will
                include only one lease term and the dollar buyout amount will be
                applied at the end of that lease term. If no value is entered
                the study will show subsequent lease terms up to the 20 year
                lookout.
            </div>
        );
    };
    return (
        <>
            <FontAwesomeIcon
                id="dollarBuyoutInfo"
                className={iconClassName}
                icon="info-circle"
            />
            <Tooltip
                className="tooltip-wrap-text"
                target="#dollarBuyoutInfo"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                width={toolTipWidth}
                position={toolTipPosition}
                contentRender={renderToolTipContent}
            />
        </>
    );
};
