import notify from "devextreme/ui/notify";
import { ASSETS_URL } from "../stores/assetStore";
import { GenerateNewAssetIdParams } from "../types/generateNewAssetIdParams";

const handleResponse = async (response: Response) => {
    if (!response.ok)
        throw Error(
            response.status === 400
                ? await response.text()
                : "An error occured while generating the new asset id."
        );
    return response;
};

export const generateNewAssetIdAsync = async (
    params: GenerateNewAssetIdParams
) => {
    var jsonString = JSON.stringify(params);
    return await fetch(ASSETS_URL + "generatenewassetid", {
        method: "POST",
        body: jsonString,
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(handleResponse)
        .then((response) => response.text())
        .catch((err) => {
            notify(
                {
                    message: err.message,
                    position: {
                        my: "center top",
                        at: "center top",
                    },
                },
                "error",
                5000
            );
        });
};
