import { createStore } from "devextreme-aspnet-data-nojquery";
import { Asset } from "../types/asset";

const KEY_VALUE: keyof Asset = "id";

const URL = "/api/assets/autocomplete";

export const autoAssetStore = createStore({
    key: KEY_VALUE,
    loadUrl: URL,
});
