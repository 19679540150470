
function addMonthsToDateUtc(fromDate: Date, monthsToAdd: number) {
	const copyDate = new Date(fromDate);

	const copyDateMonth = copyDate.getUTCMonth()

	copyDate.setUTCMonth(copyDateMonth + monthsToAdd + 1, 0);
	return copyDate;
}

export const calculateLeaseEndValue = (leaseStart?: Date | null, leaseTerm?: number | null) => {
    return leaseStart && leaseTerm ? addMonthsToDateUtc(leaseStart, leaseTerm) : null
}