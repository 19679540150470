import CustomStore from "devextreme/data/custom_store";
import { Study } from "../types/study";

const KEY_VALUE: keyof Study = "id";

type KeyType = Study[typeof KEY_VALUE];

const handleErrors = (response: Response) => {
    if (!response.ok) throw Error(response.statusText);
    return response;
};

export const STUDIES_URL = "/api/studies/";

const loadAsync = async (key?: KeyType) =>
    await fetch(key ? STUDIES_URL + key : STUDIES_URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(handleErrors)
        .then((result) => result.json());

export const studyStore = new CustomStore({
    loadMode: 'raw',
    key: KEY_VALUE,
    byKey: async (key) => await loadAsync(key),
    load: async () => await loadAsync(),
    insert: async (values) => {
        //The next line desctructures and discards the key value before inserting. Only the other paramaters remain as insertParams.
        const {[KEY_VALUE]: _, ...insertParams } = values
        var jsonString = JSON.stringify(insertParams);
        return await fetch(STUDIES_URL, {
            method: "POST",
            body: jsonString,
            headers: {
                "Content-Type": "application/json",
            },
        }).then(handleErrors).then(result=>result.json())
    },
    update: async (key, values) => {
        var jsonString = JSON.stringify(values);
        await fetch(STUDIES_URL + key, {
            method: "PUT",
            body: jsonString,
            headers: {
                "Content-Type": "application/json",
            },
        }).then(handleErrors);
    },
    remove: async (key) => {
        await fetch(STUDIES_URL + key, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(handleErrors);
    },
});