import CustomStore from "devextreme/data/custom_store";
import authService from "../components/api-authorization/AuthorizeService";
import { AssetServiceLife } from "../types/assetServiceLife";

const KEY_VALUE: keyof AssetServiceLife = "id";

type KeyType = AssetServiceLife[typeof KEY_VALUE];

const handleErrors = (response: Response) => {
    if (!response.ok) throw Error(response.statusText);
    return response;
};

const URL = "/api/assetservicelives/";

const token = authService.getAccessToken();

const loadAsync = async (key?: KeyType) =>
    await fetch(key ? URL + key : URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
        },
    })
        .then(handleErrors)
        .then((result) => result.json());

export const assetServiceLifeStore = new CustomStore({
    loadMode: 'raw',
    key: KEY_VALUE,
    byKey: async (key) => await loadAsync(key),
    load: async () => await loadAsync(),

    insert: async(values) => {
        //The next line desctructures and discards the key value before inserting. Only the other paramaters remain as insertParams.
        const {[KEY_VALUE]: _, ...insertParams } = values
        var jsonString = JSON.stringify(insertParams);
        return await fetch(URL, {
            method: "POST",
            body: jsonString,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(handleErrors).then(result=>result.json())
    },

    update: async(key, values) => {
        var jsonString = JSON.stringify(values);
        return await fetch(URL + key, {
            method: "PUT",
            body: jsonString,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + authService.getAccessToken()
            }
        }).then(handleErrors);
    },

    remove: async(key) => {
        await fetch(URL + key, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + authService.getAccessToken()
            }
        }).then(handleErrors)
    } 
});
