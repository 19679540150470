import React from "react";

import DataGrid, {
    Column,
    Export,
    FilterPanel,
    HeaderFilter,
    Pager,
    Scrolling,
    SearchPanel,
    Selection,
} from "devextreme-react/data-grid";

import dxDataGrid from "devextreme/ui/data_grid";

import { calculateWeekendFilterExpression } from "../datagrid helpers/calculateWeekendFilterExpression";
import { exportAssetGridAsync } from "../datagrid helpers/exportAssetGridAsync";
import { orderWeekendHeaderFilter } from "../datagrid helpers/orderWeekendHeaderFilter";
import { assetsBulkStore } from "../stores/assetsBulkStore";

const allowedPageSizes = [10, 20, 50, 100];



const AllAssets = () => {

    const onExporting = async(e: { component?: dxDataGrid; cancel?: boolean }) => await exportAssetGridAsync(e, "AllAssets")

    return (
        <>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                allowColumnReordering={true}
                onExporting={onExporting}
                repaintChangesOnly={true}
            >
                <Export
                    enabled={true}
                    fileName="AllAssets"
                    allowExportSelectedData={true}
                />
                <FilterPanel visible={true} />
                <Scrolling
                    useNative={false}
                    scrollByContent={true}
                    scrollByThumb={true}
                    showScrollbar="always"
                    columnRenderingMode="virtual"
                />
                <Selection mode="multiple" />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} Assets)"
                />

                <HeaderFilter visible={true} allowSearch={true} />
                <SearchPanel visible={true} />

                <Column
                    dataField="id"
                    caption="Id"
                    allowEditing={false}
                    visible={false}
                />
                <Column dataField="clubName" caption="Club Name" />
                <Column dataField="fileName" caption="File Name" />
                <Column
                    dataField="assetId"
                    caption="Asset Id"
                    allowEditing={false}
                />
                <Column
                    dataField="assetCodingClass"
                    caption="Asset Coding Class"
                    allowEditing={false}
                />
                <Column
                    dataField="structureLocationName"
                    caption="Structure/Location Name"
                />
                <Column dataField="ledgerId" caption="Ledger Id" />
                <Column dataField="roomAreaName" caption="Room/Area Name" />
                <Column dataField="assetType" caption="Asset Type" />
                <Column
                    dataField="isLeased"
                    caption="Lease Status"
                    dataType="boolean"
                />
                <Column dataField="assetSubTypeName" caption="Asset Sub-Type" />
                <Column
                    dataField="assetManufacturerName"
                    caption="Asset Manufacturer"
                />
                <Column
                    dataField="description"
                    caption="Asset Detail Description"
                />
                <Column dataField="assetModelName" caption="Asset Model Id"/>
                <Column dataField="qty" caption="Qty" dataType="number" />
                <Column dataField="uom" caption="UOM" />
                <Column dataField="yearInstalled" caption="Year Installed" />
                <Column
                    dataField="assetServiceLife"
                    caption="Asset Service Life"
                />
                <Column dataField="assetLifeCycle" caption="Asset Life Cycle" />
                <Column
                    dataField="unitReplacementValue"
                    caption="Unit Replacement Value"
                    dataType="number"
                />
                <Column
                    dataField="aggregateReplacementValue"
                    caption="Aggregate Replacement Value"
                    dataType="number"
                    allowExporting={true}
                />
                <Column
                    dataField="initialRYAuto"
                    caption="Initial RY Auto"
                    dataType="number"
                    allowExporting={true}
                />
                <Column
                    dataField="initialRYManual"
                    caption="Initial RY Manual"
                    dataType="number"
                />
                <Column
                    dataField="lastRYCycle"
                    caption="Last RY Cycle"
                    dataType="number"
                />
                <Column
                    dataField="datetimeExported"
                    allowExporting={false}
                    dataType="date"
                    calculateFilterExpression={calculateWeekendFilterExpression}
                    format="M/d/yyyy, HH:mm"
                >
                    <HeaderFilter dataSource={orderWeekendHeaderFilter} />
                </Column>
                <Column
                    dataField="isExported"
                    caption="Export Status"
                    dataType="boolean"
                    allowExporting={false}
                />
                <Column
                    dataField="userLastExportedBy"
                    caption="Last Exported By"
                    allowExporting={false}
                />
                <Column dataField="createdBy" allowExporting={false} />
                <Column
                    dataField="dateCreated"
                    allowExporting={false}
                    dataType="date"
                    calculateFilterExpression={calculateWeekendFilterExpression}
                    format="M/d/yyyy, HH:mm"
                >
                    <HeaderFilter dataSource={orderWeekendHeaderFilter} />
                </Column>
                <Column dataField="modifiedBy" allowExporting={false} />
                <Column
                    dataField="dateModified"
                    allowExporting={false}
                    allowFiltering={true}
                    dataType="date"
                    calculateFilterExpression={calculateWeekendFilterExpression}
                    format="M/d/yyyy, HH:mm"
                >
                    <HeaderFilter dataSource={orderWeekendHeaderFilter} />
                </Column>
            </DataGrid>
        </>
    );
};



const gridDataSource = {
    store: assetsBulkStore,
};

export default AllAssets;
