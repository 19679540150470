import { Box, Button, LoadPanel, NumberBox, Tooltip } from "devextreme-react";
import React from "react";

import { Item } from "devextreme-react/box";
import notify from "devextreme/ui/notify";
import { useCallback, useEffect, useMemo, useState } from "react";
import { STUDY_CONSTANTS } from "../constants/studyConstants";
import { INFLATION_URL } from "../stores/inflationStore";

const BASE_INFLATION_RATE_TEXT = "Base Inflation Rate";

interface BaseInflationEditorProps {
    onAfterSaveSuccess?: () => void;
    showGenerateButton?: boolean;
}

const BASE_INFLATION_URL = INFLATION_URL + "baseinflation/";

export const BaseInflationEditor = ({
    onAfterSaveSuccess,
    showGenerateButton = false,
}: BaseInflationEditorProps) => {
    const [isSaving, setIsSaving] = useState(false);
    const [initialInflationRate, setInitialInflationRate] = useState<
        number | undefined
    >(undefined);
    const [currentInflationRate, setCurrentInflationRate] = useState<
        number | undefined
    >(undefined);

    const loadBaseInflation = useCallback(async () => {
        await fetch(BASE_INFLATION_URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response: Response) => {
                if (!response.ok) {
                    if (response.status === 404) return null;
                    throw new Error();
                }
                return response.json();
            })
            .then((baseInflation: number | null) => {
                if (!Number.isNaN(baseInflation)) {
                    setInitialInflationRate(baseInflation as number);
                    setCurrentInflationRate(baseInflation as number);
                }
            })
            .catch(() => {
                notify(
                    {
                        message: `An error occured while attempting to load the current ${BASE_INFLATION_RATE_TEXT} for the study.`,
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    },
                    "error",
                    5000
                );
            });
    }, []);

    useEffect(() => {
        loadBaseInflation();
    }, [loadBaseInflation]);

    const onSaveInflationClicked = useCallback(async () => {
        setIsSaving(true);

        await fetch(BASE_INFLATION_URL, {
            method: "PUT",
            body: JSON.stringify(currentInflationRate),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(handleErrors)
            .then(async () => {
                await loadBaseInflation();
                if (onAfterSaveSuccess) {
                    onAfterSaveSuccess();
                }
                notify(
                    {
                        message: `You successfully updated the ${BASE_INFLATION_RATE_TEXT}.`,
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    },
                    "success",
                    5000
                );
            })
            .catch(() => {
                notify(
                    {
                        message: `An error occured while attempting to update ${BASE_INFLATION_RATE_TEXT}. Try again.`,
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    },
                    "error",
                    5000
                );
            })
            .finally(() => {
                setIsSaving(false);
            });
    }, [currentInflationRate, loadBaseInflation, onAfterSaveSuccess]);

    const actionsDisabled = useMemo(
        () => currentInflationRate === initialInflationRate,
        [currentInflationRate, initialInflationRate]
    );

    const onCancelClicked = () => {
        setCurrentInflationRate(initialInflationRate || undefined);
    };

    useEffect(() => {
        setCurrentInflationRate(initialInflationRate || undefined);
    }, [initialInflationRate]);

    const onInflationRateChanged = (e: { value?: number }) => {
        setCurrentInflationRate(e.value);
    };

    const toolTipText = `The ${BASE_INFLATION_RATE_TEXT} is the rate of increase in prices
  over a given period of time. Modifying the
  ${BASE_INFLATION_RATE_TEXT} value will adjust the inflation for
  years ${STUDY_CONSTANTS.BASE_INFLATION_START_YEAR} through ${
        STUDY_CONSTANTS.STUDY_LOOKOUT_YEARS
    } of the study. To modify the inflation values
  for the first ${
      STUDY_CONSTANTS.BASE_INFLATION_START_YEAR - 1
  } years of the study, edit the individual years in
  the Inflation Grid.`;

    return (
        <>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                showPane={true}
                showIndicator={true}
                visible={isSaving}
                shading={true}
                closeOnOutsideClick={false}
            />
            <Box direction="row" align="center" crossAlign="center">
                <Item baseSize="auto">
                    <Box direction="row" align="end" width={200}>
                        <Item baseSize={"auto"}>
                            <i
                                id="baseInflationInfo"
                                className="dx-icon-info"
                            />
                            <Tooltip
                                target="#baseInflationInfo"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                width={300}
                            >
                                <div className="cb-tooltip-text-wrap">
                                    {toolTipText}
                                </div>
                            </Tooltip>
                        </Item>
                        <Item baseSize={"auto"}>
                            <div className="pr-2">
                                {BASE_INFLATION_RATE_TEXT}:
                            </div>
                        </Item>
                    </Box>
                </Item>
                <Item baseSize={200}>
                    <div className="d-inline-flex align-items-center">
                        <NumberBox
                            value={currentInflationRate}
                            onValueChanged={onInflationRateChanged}
                            format={"#0.0%"}
                        />
                        {showGenerateButton ? (
                            <Button
                                type="default"
                                icon="chevrondoubleright"
                                hint={"Generate Inflation Table"}
                                onClick={onSaveInflationClicked}
                            />
                        ) : (
                            <>
                                <Button
                                    type="success"
                                    icon="save"
                                    hint={`Save ${BASE_INFLATION_RATE_TEXT}`}
                                    onClick={onSaveInflationClicked}
                                    disabled={actionsDisabled}
                                />
                                <Button
                                    type="danger"
                                    icon="undo"
                                    hint={`Reset ${BASE_INFLATION_RATE_TEXT}`}
                                    onClick={onCancelClicked}
                                    disabled={actionsDisabled}
                                />
                            </>
                        )}
                    </div>
                </Item>
            </Box>
        </>
    );
};

function handleErrors(response: Response) {
    if (!response.ok) throw Error(response.statusText);
    return response;
}
