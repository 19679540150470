import { Asset } from "../types/asset";
import { createDataFields } from "../utility functions/createDataFields";

export const ASSET_DATA_FIELDS = createDataFields<Asset>()({
    ID: "id",
    STRUCTURE_LOCATION_NAME: "structureLocationName",
    ROOM_AREA_NAME: "roomAreaName",
    STUDY_ROOM_ID: "studyRoomId",
    ASSET_CODING_CLASS:  "assetCodingClassId",
    ASSET_TYPE_ID: "assetTypeId",
    STUDY_STRUCTURE_ID: "studyStructureId",
    IS_LEASED: "isLeased",
    ASSET_SUB_TYPE_NAME: "assetSubTypeName",
    ASSET_MANUFACTURER_NAME: "assetManufacturerName",
    DESCRIPTION: "description",
    ASSET_MODEL_NAME: "assetModelName",
    ASSET_ID: "assetId",
    IS_LEGACY_LEASE: "isLegacyLease",
    QTY: "qty",
    UNIT_OF_MEASURE_ID: "unitOfMeasureId",
    YEAR_INSTALLED: "yearInstalled",
    ASSET_SERVICE_LIFE_ID: "assetServiceLifeId",
    ASSET_LIFE_CYCLE: "assetLifeCycle",
    UNIT_REPLACEMENT_VALUE: "unitReplacementValue",
    AGGREGATE_REPLACEMENT_VALUE: "aggregateReplacementValue",
    INITIAL_RY_AUTO: "initialRYAuto",
    INITIAL_RY_MANUAL: "initialRYManual",
    LAST_RY_CYCLE: "lastRYCycle",
    LEDGER_ID: "ledgerId",
    NOTES: "notes",

    //New Lease Properties
    LEASE_START: "leaseStart",
    LEASE_END: "leaseEnd",
    LEASE_DOLLAR_BUYOUT_AMOUNT: "leaseDollarBuyoutAmount",
    LEASE_MONTHLY_PAYMENT: "leaseMonthlyPayment",
    LEASE_TERM: "leaseTerm",
    LEASE_TOTAL_PAYMENT: "leaseTotalPayment",
    LEASE_YEARLY_PAYMENT: "leaseYearlyPayment"
})