import { WEEKEND_FILTER } from "../constants/weekendFilter";

type HeaderFilterData = {
    dataSource: {
        postProcess: (
            results: Array<{ text: string; value: string }>
        ) => Array<{ text: string; value: string }>;
    };
}

export const orderWeekendHeaderFilter = (data: HeaderFilterData) => {
    data.dataSource.postProcess = (results) => [
        ...results,
        WEEKEND_FILTER,
    ];
};