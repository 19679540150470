import { Autocomplete } from "devextreme-react";
import { IAutocompleteOptions } from "devextreme-react/autocomplete";
import DataSource from "devextreme/data/data_source";
import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import { autoAssetStore } from "../stores/autoAssetStore";
import { ValidKey } from "../types/ValidKey";
import { Asset } from "../types/asset";
import { OmitStrict } from "../types/omitStrict";
import { getUpdatedAssetFilterValue } from "../utility functions/getUpdatedAssetFilter";

interface GroupedAssets {
    key: string;
    items: Asset[];
}

const manufacturerKey: keyof Asset = "assetManufacturerName";
const subTypeKey: keyof Asset = "assetSubTypeName";

/*The props for this component contain a Generic K that is assigned to 
  valueExpr and is restricted to be a property on an Asset the value for which is a string. 
  If the valueExpr is 'assetManufacturerName' or 'assetSubTypeName', the associated filterBy 
  value cannot be set.
*/
interface AssetAutocompleteProps<K extends ValidKey<Asset, string | null>>
    extends OmitStrict<
        IAutocompleteOptions,
        "dataSource" | "valueExpr" | "onValueChanged"
    > {
    valueExpr: K;
    filterByManufacturerValue?: K extends typeof manufacturerKey
        ? never
        : string;
    filterBysubTypeValue?: K extends typeof subTypeKey ? never : string;
    children: ReactNode;
}

/*
    This component is a reusable Autocomplete for Assets that is built on top of
    the Devextreme Autocomplete and automatically filters based on other selected 
    values such as manufacturer and subtype, both of which are optional and if not 
    passed the AutoComplete will not be filtered by those values.
*/

export const AssetAutocomplete = <K extends string & ValidKey<Asset, string>>({
    valueExpr,
    filterByManufacturerValue,
    filterBysubTypeValue,
    children,
    searchMode = "contains", //Optional: defaults to 'startswith'
    showClearButton = true, //Optional: defaults to true
    minSearchLength = 3, //Optional: defaults to 3
    ...rest
}: AssetAutocompleteProps<K>) => {
    const dataSource = useMemo(
        () =>
            new DataSource({
                store: autoAssetStore,
                /*The select property is not required for this component to function, 
                  however is used to only the object property needed by this component and no other properties.
                */
                select: valueExpr,
                //The group property AND the postprocess property are required in order to only return distinct values.
                group: valueExpr,
                postProcess: (result: GroupedAssets[]) => {
                    const keysList =
                        valueExpr === manufacturerKey
                            ? result.map((g) =>
                                  g.key
                                      .toLowerCase()
                                      .replace(/(^\w|\s\w)/g, (m) =>
                                          m.toUpperCase()
                                      )
                              )
                            : result.map((g) => g.key);

                    const uniqueList = [...new Set(keysList)];

                    return uniqueList.map((key) => ({ [valueExpr]: key }));
                },
            }),
        [valueExpr]
    );

    const autoCompleteRef = useRef<Autocomplete>(null);

    /*
    The Side Effect that needs to happen is that whenever the filterByManufacturerValue
    or filterBySubtypeValue changes, the dataSource should reload the filtered values
    to be suggested by the autocomplete.
    */
    useEffect(() => {
        const updatedFilterValue = getUpdatedAssetFilterValue(
            filterByManufacturerValue,
            filterBysubTypeValue
        );

        if (updatedFilterValue) {
            const currentDataSource =
                autoCompleteRef.current?.instance.getDataSource();
            if (currentDataSource) {
                currentDataSource.filter(updatedFilterValue);
                currentDataSource.reload();
            }
        }
    }, [
        filterByManufacturerValue,
        filterBysubTypeValue,
    ]);

    return (
        <Autocomplete
            {...rest}
            ref={autoCompleteRef}
            valueExpr={valueExpr}
            dataSource={dataSource}
            searchMode={searchMode}
            minSearchLength={minSearchLength}
            showClearButton={showClearButton}
        >
            {children}
        </Autocomplete>
    );
};
