
import { dxDataGridColumn } from "devextreme/ui/data_grid";
import { WEEKEND_FILTER } from "../constants/weekendFilter";
import { Asset } from "../types/asset";

export function calculateWeekendFilterExpression (this: dxDataGridColumn,
    value: string,
    selectedFilterOperations: string | null,
    target: string
) {
    const dataField = this.dataField as keyof Asset

    const dayOfTheWeek = (rowData: Asset) =>{
        const fieldValue = rowData[dataField]

        const isValidDateParam = typeof value === "number" || typeof value === "string"

        if(isValidDateParam){
            const date = new Date(fieldValue as string | number)

            const isValidDate = !isNaN(date.valueOf())

            if(isValidDate){
                return date.getDay()
            }
        }
    } 

    if (dataField && target === "headerFilter" && value === WEEKEND_FILTER.value) {
        return [[dayOfTheWeek, "=", 0], "or", [dayOfTheWeek, "=", 6]];
    }
};

