import React from 'react';

import { Button } from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';
import ValidationSummary from 'devextreme-react/validation-summary';
import notify from 'devextreme/ui/notify';
import { Container } from 'reactstrap';
import authService from './api-authorization/AuthorizeService';

import { DateBox, Form, ValidationGroup } from 'devextreme-react';
import { CustomRule, SimpleItem } from 'devextreme-react/form';
import { NumberBox } from 'devextreme-react/number-box';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import CustomStore from 'devextreme/data/custom_store';
import { all, create } from 'mathjs';

import './CRSEntry.css';

import { NumericRule, PatternRule, RangeRule, RequiredRule, Validator } from 'devextreme-react/validator';

import { FORMAT } from "../constants/format";
import { LEASE_TERM_PARAMS } from '../constants/leaseTermParams';
import { calculateAggregateReplacementValue } from '../utility functions/calculateAggregateReplacementValue';
import { calculateLeaseEndValue } from '../utility functions/calculateLeaseEndValue';
import { calculateLeaseTotalPayment } from '../utility functions/calculateLeaseTotalPayment';
import { calculateLeaseYearlyPayment } from '../utility functions/calculateLeaseYearlyPayment';
import { AssetAutoNumberBox } from './AssetAutoNumberBox';
import { AssetAutocomplete } from './AssetAutocomplete';
import { AssetEntryPanelSummary } from './AssetEntryPanelSummary';
import { DollarBuyoutInfo } from './DollarBuyoutInfo';

const studyStructureLookup = [];
const studyRoomsLookup = [];
const studyAssetsLookup = [];
const assetSubtypeAutocomplete = [];
const assetManufacturerAutocomplete = [];
const UnsyncedRooms = [];
const UnsyncedAssets = [];


const studyRoomTemplate = {
	studyStructureId: -1,
	name: '',
	roomTypeId: -1
};

const math = create(all);



class CRSEntry extends React.Component {


	constructor(props) {
		super(props);

		this.topOfForm = React.createRef();
		this.assetValidationGroup = React.createRef();

		this.state = {
			//Asset Details
			StructureId: "",
			StudyRoomId: "",
			AssetCodingClassId: "",
			AssetTypeId: "",
			AssetSubTypeName: "",
			AssetManufacturerName: "",
			AssetModelName: "",
			IsLeased: false,
			Description: "",
			Qty: null,
			UnitOfMeasureId: "",
			YearInstalled: null,
			AssetServiceLifeID: "",
			AssetLifeCycle: null,
			Notes: "",
			UnitReplacementValue: null,
			AggregateReplacementValue: null,
			InitialRYAuto: null,
			InitialRYManual: null,
			LastRYCycle: null,
			LedgerId: undefined,

			NewRoomPopupVisible: false,
			NewRoomStructureId: "",
			NewRoomName: "",
			NewRoomType: 1,
			studyRoomsLookupFiltered: [],
			RoomCount: 0,
			AssetCountThisRoom: 0,
			DBSyncStatus: "Synced with Server",
			UnsyncedRoomCount: 0,
			UnsyncedAssetCount: 0,
			AssetsThisRoom: [],
			EditAssetId: 0,
			AssetLifeCycleMinimum: 0,
			AssetLifeCycleMaximum: 100,
			overrideDisabled: false,
			
			//lease Asset Params
			leaseStart: undefined,
			leaseTerm: undefined,
			leaseEnd: undefined,
			leaseDollarBuyoutAmount: undefined,
			leaseMonthlyPayment: undefined,
			leaseYearlyPayment: undefined,
			leaseTotalPayment: undefined,
			isLegacyLease: false
		};

		loadStudyStructureLookup();
		loadStudyRoomsLookup();
		loadStudyAssetsLookup();
		this.studyRoomsLookupFiltered = [];
		this.assetSubtypeAutocomplete = [];
		this.assetManufacturerAutocomplete = [];

		this.numbersOnlyRule = { 'h': /[0-9]/ };

		this.validationRules = {
			structure: [
				{ type: 'required', message: 'Structure is required.' },
			],
			room: [
				{ type: 'required', message: 'Room is required.' }
			],
			assetSubType: [
				{ type: 'required', message: 'Asset Subtype is required.' }
			]
		};

		this.validateForm = (e) => {
			e.component.validate();
		};

		this.onStructureChanged = this.onStructureChanged.bind(this);
		this.onRoomChanged = this.onRoomChanged.bind(this);
		this.onAssetCodingClassChanged = this.onAssetCodingClassChanged.bind(this);
		this.onAssetTypeChanged = this.onAssetTypeChanged.bind(this);
		this.onAssetSubtypeChanged = this.onAssetSubtypeChanged.bind(this);
		this.onAssetManufacturerNameChanged = this.onAssetManufacturerNameChanged.bind(this);
		this.onAssetModelNameChanged = this.onAssetModelNameChanged.bind(this);
		this.onAssetLeaseStatusChanged = this.onAssetLeaseStatusChanged.bind(this);
		this.onAssetDetailDescriptionChanged = this.onAssetDetailDescriptionChanged.bind(this);
		this.onQuantityChanged = this.onQuantityChanged.bind(this);
		this.onUomChanged = this.onUomChanged.bind(this);
		this.onYearInstalledChanged = this.onYearInstalledChanged.bind(this);
		this.onAssetServiceLifeChanged = this.onAssetServiceLifeChanged.bind(this);
		this.onAssetLifeCycleChanged = this.onAssetLifeCycleChanged.bind(this);
		this.onGeneralNotesChanged = this.onGeneralNotesChanged.bind(this);
		this.onUnitReplacementValueChanged = this.onUnitReplacementValueChanged.bind(this);
		this.onInitialRYAutoChanged = this.onInitialRYAutoChanged.bind(this);
		this.onInitialRYManualChanged = this.onInitialRYManualChanged.bind(this);
		this.onLastRYCycleChanged = this.onLastRYCycleChanged.bind(this);
		this.onLedgerIdChanged = this.onLedgerIdChanged.bind(this);

		this.handleRoomPopupHidden = this.handleRoomPopupHidden.bind(this);
		this.handleRoomPopupShowing = this.handleRoomPopupShowing.bind(this);
		this.onNewAssetClick = this.onNewAssetClick.bind(this);
		this.onSaveNewAssetClick = this.onSaveNewAssetClick.bind(this);
		this.onSaveNewAssetClickOffline = this.onSaveNewAssetClickOffline.bind(this);
		this.onSaveStartNewRoomClick = this.onSaveStartNewRoomClick.bind(this);
		this.onSaveStartNewRoomClickOffline = this.onSaveStartNewRoomClickOffline.bind(this);
		this.onSaveCodingTypeClick = this.onSaveCodingTypeClick.bind(this);
		this.onSaveCodingTypeClickOffline = this.onSaveCodingTypeClickOffline.bind(this);
		this.onSavingDuplicateClick = this.onSavingDuplicateClick.bind(this);
		this.onSavingDuplicateClickOffline = this.onSavingDuplicateClickOffline.bind(this);
		this.disableOverrides = this.disableOverrides.bind(this)
		this.overrideUnitReplacementValue = this.overrideUnitReplacementValue.bind(this)

		this.onNewRoomClick = this.onNewRoomClick.bind(this);
		this.onNewRoomButtonClick = this.onNewRoomButtonClick.bind(this);

		this.onNewRoomStructureChanged = this.onNewRoomStructureChanged.bind(this);
		this.onNewRoomNameChanged = this.onNewRoomNameChanged.bind(this);
		this.onNewRoomTypeChanged = this.onNewRoomTypeChanged.bind(this);

		this.handleDBSyncButtonClicked = this.handleDBSyncButtonClicked.bind(this);
		this.SyncRoomsWithDB = this.SyncRoomsWithDB.bind(this);

		this.updateStatesAfterSync = this.updateStatesAfterSync.bind(this);

		this.editPanelItemClicked = this.editPanelItemClicked.bind(this);
		this.onQuantityFocusOut = this.onQuantityFocusOut.bind(this);

		this.onLeaseStartChanged = this.onLeaseStartChanged.bind(this)
		this.onLeaseMonthlyPaymentChanged = this.onLeaseMonthlyPaymentChanged.bind(this)
		this.onLeaseTermChanged = this.onLeaseTermChanged.bind(this)
		this.onLeaseDollarBuyoutChanged = this.onLeaseDollarBuyoutChanged.bind(this)
	}
	async componentDidMount() {
		const user = await authService.getUser();
		this.setState({
			CurrentUser: user
		});
	}
	onNewRoomNameChanged(e) {
		let newValue = e.value;
		this.setState({
			NewRoomName: newValue
		});
	}
	onNewRoomTypeChanged(e) {
		let newValue = e.value;
		this.setState({
			NewRoomType: newValue
		});
	}
	onNewRoomStructureChanged(e) {
		let newValue = e.value;
		this.setState({
			NewRoomStructureId: newValue
		});
	}
	updateStatesAfterSync() {
		// Make sure it's cleared, since the index doesn't update
		UnsyncedAssets.length = 0;

		// Make sure the panel items are updated to stop the sync.
		if (UnsyncedAssets.length == 0 && UnsyncedRooms.length == 0) {
			this.setState({
				DBSyncStatus: "Synced with Server"
			});
		}
		let unsyncedAssetCount = UnsyncedAssets.length;
		let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == this.state.StudyRoomId);
		//console.log(assetsThisRoom);
		this.setState({
			UnsyncedAssetCount: unsyncedAssetCount,
			AssetsThisRoom: assetsThisRoom
		});
	}
	handleDBSyncButtonClicked(evt) {
		this.SyncRoomsWithDB();
		this.updateStatesAfterSync();
	}
	editPanelItemClicked(editId) {
		this.disableOverrides()
		assetEdit.load(editId)
			.then(
				(data) => {
					this.populateEdit(data);
				}
			).done();

	}

    onLeaseStartChanged(e) {
		const {value: leaseStart} = e
        this.setState(state=>{
			const leaseEndDate = calculateLeaseEndValue(leaseStart, state.leaseTerm)
			return { 
			leaseStart: leaseStart, 
			leaseEnd: leaseEndDate
		}})
    }

    onLeaseTermChanged(e) {
		const {value: leaseTerm} = e
        this.setState(state=>({ 
			leaseTerm: leaseTerm, 
			leaseEnd: calculateLeaseEndValue(state.leaseStart, leaseTerm),
			leaseTotalPayment: calculateLeaseTotalPayment(state.leaseMonthlyPayment, leaseTerm),
		}));
    }

    onLeaseMonthlyPaymentChanged(e) {
		const {value: leaseMonthlyPayment} = e
        this.setState(state=>({ 
			leaseMonthlyPayment: leaseMonthlyPayment,
			leaseYearlyPayment: calculateLeaseYearlyPayment(leaseMonthlyPayment),
			leaseTotalPayment: calculateLeaseTotalPayment(leaseMonthlyPayment, state.leaseTerm)
		}));
    }

    onLeaseDollarBuyoutChanged(e) {
        this.setState({leaseDollarBuyoutAmount: e.value});
    }

	render() {
		const {leaseStart, IsLeased, isLegacyLease} = this.state

		const leaseStartYear =  leaseStart ? new Date(leaseStart).getFullYear() : undefined

		const showNewLeaseView = IsLeased && !isLegacyLease

		const greaterThanZeroIfLeased = (e) =>{
			const value = e.value;
			return showNewLeaseView && value != null ? value > 0 : true
		}

		const greaterThanZeroIfNotLeased = (e) => {
			const value = e.value;
			return !showNewLeaseView && value != null ? value > 0 : true
		}

		const requiredIfLeased = (e) =>{
			return showNewLeaseView ? e.value : true
		}
	
		const validateLeaseTermMaxValue = (e) =>{
			const {value} = e
			return showNewLeaseView && value ? value <= LEASE_TERM_PARAMS.MAX : true
		}
		
		return (
			<Container>
				<>
					<AssetEntryPanelSummary
						assetCountThisRoom={this.state.AssetCountThisRoom}
						dbSyncStatus={this.state.DBSyncStatus}
						assetsThisRoom={this.state.AssetsThisRoom}
						unsyncedRoomCount={this.state.UnsyncedRoomCount}
						unsyncedAssetCount={this.state.UnsyncedAssetCount}
						editingItemId={this.state.EditAssetId}
						handleDBSyncButtonClicked={this.handleDBSyncButtonClicked}
						editPanelItemClicked={this.editPanelItemClicked}
						updateStatesAfterSync={this.updateStatesAfterSync}
					/>
					
					<form>
					<ValidationGroup ref={this.assetValidationGroup}>
					<div className="form">
						<div className="dx-fieldset">
							<div className="dx-fieldset-header" ref={this.topOfForm}>Where are you?</div>
							<div className="dx-field">
								<div className="dx-field-label">Structure</div>
								<SelectBox
									className="dx-field-value"
									placeholder="Choose Structure..."
									showClearButton={true}
									dataSource={studyStructureLookup}
									valueExpr="id"
									displayExpr="name"
									searchEnabled={true}
									value={this.state.StructureId}
									onValueChanged={this.onStructureChanged}>
									<RequiredRule message="Structure is required." />
									<Validator>
										<RequiredRule message="Structure is required." />
									</Validator>
								</SelectBox>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Room
									<Button
										className="addNewRoomButton dx-field-value"
										width={200}
										text="Add New Room"
										type="normal"
										stylingMode="contained"
										useSubmitBehavior={false}
										onClick={this.onNewRoomButtonClick}
									/>
								</div>
								<SelectBox
									className="dx-field-value"
									placeholder="Choose Existing Room..."
									showClearButton={true}
									dataSource={this.state.studyRoomsLookupFiltered}
									valueExpr="id"
									displayExpr="name"
									value={this.state.StudyRoomId}
									searchEnabled={true}
									onValueChanged={this.onRoomChanged}
								>
									<Validator>
										<RequiredRule message="Room is required." />
									</Validator>
								</SelectBox>

							</div>
						</div>
						<div className="dx-fieldset">
							<div className="dx-fieldset-header">Enter Asset Details</div>
							<div className="dx-field">
								<div className="dx-field-label">Asset Coding Class</div>
								<div className="dx-field-value" >
									<SelectBox
										dataSource={assetCodingClass}
										placeholder="Asset Coding Class..."
										valueExpr="id"
										displayExpr="name"
										showClearButton={true}
										searchEnabled={true}
										value={this.state.AssetCodingClassId}
										onValueChanged={this.onAssetCodingClassChanged}
									/>
								</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Asset Type</div>
								<div className="dx-field-value" >
									<SelectBox
										dataSource={assetType}
										placeholder="Asset Type..."
										valueExpr="id"
										displayExpr="name"
										showClearButton={true}
										searchEnabled={true}
										value={this.state.AssetTypeId}
										onValueChanged={this.onAssetTypeChanged}
									/>
								</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Leased?</div>
								<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
									<CheckBox
										className="dx-field-value"
										value={this.state.IsLeased}
										onValueChanged={this.onAssetLeaseStatusChanged}
										defaultValue={false}
									/>
								</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Asset Sub-Type</div>
								<AssetAutocomplete className="dx-field-value"
									placeholder="Asset Sub-Type..."
									valueExpr="assetSubTypeName"
									value={this.state.AssetSubTypeName}
									onValueChanged={this.onAssetSubtypeChanged}
									>
									<Validator>
										<RequiredRule message="Asset Subtype Name is required."/>
									</Validator>
								</AssetAutocomplete>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Asset Manufacturer</div>
								<AssetAutocomplete className="dx-field-value"
									placeholder="Asset Manufacturer..."
									valueExpr="assetManufacturerName"
									value={this.state.AssetManufacturerName}
									onValueChanged={this.onAssetManufacturerNameChanged} 
									/>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Asset Detail Description</div>
								<AssetAutocomplete className="dx-field-value"
									placeholder="Asset Detail Description..."
									valueExpr="description"
									value={this.state.Description}
									filterByManufacturerValue={this.state.AssetManufacturerName}
									filterBysubTypeValue={this.state.AssetSubTypeName}
									onValueChanged={this.onAssetDetailDescriptionChanged} 
									/>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Asset Model ID</div>
								<AssetAutocomplete className="dx-field-value"
									placeholder="Asset Model Id..."
									valueExpr="assetModelName"
									value={this.state.AssetModelName}
									filterByManufacturerValue={this.state.AssetManufacturerName}
									filterBysubTypeValue={this.state.AssetSubTypeName}
									onValueChanged={this.onAssetModelNameChanged} 
									minSearchLength={1}
									/>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Qty</div>
								<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
								<TextBox
										className="dx-field-value"
										placeholder="Qty..."
										value={this.state.Qty}
										onValueChanged={this.onQuantityChanged}
										width="100"
										onFocusOut={this.onQuantityFocusOut}
									>
										<Validator>
											<NumericRule message="Qty only accepts numbers." />
											<PatternRule
												pattern="^-?[0-9]*$"
												message="Qty only accepts integers."
											/>
											<RangeRule
												min={0}
												message="Qty needs to be a positive number."
											/>
										</Validator>
									</TextBox>
									<SelectBox
										dataSource={uom}
										placeholder="UOM..."
										valueExpr="id"
										displayExpr="name"
										showClearButton={true}
										searchEnabled={true}
										value={this.state.UnitOfMeasureId}
										onValueChanged={this.onUomChanged}
										width="150"
										className="dx-field-value"
									/>
								</div>
							</div>
							<div className={`dx-field ${showNewLeaseView && 'dx-state-invisible'}`}>
								<div className={'dx-field-label'}>Year Installed</div> 								
								 <NumberBox
									className="dx-field-value"
									value={this.state.YearInstalled}
									onValueChanged={this.onYearInstalledChanged}
									format={FORMAT.WHOLE_NUMBER}
								>
									<Validator name="Year Installed">
										<CustomRule
											validationCallback={greaterThanZeroIfNotLeased}
											message="Year Installed needs to be a positive number."
										/>
									</Validator>
								</NumberBox>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Asset Service Life</div>
								<div className="dx-field-value" >
									<SelectBox
										dataSource={assetServiceLife}
										placeholder="Asset Service Life..."
										valueExpr="id"
										displayExpr="name"
										showClearButton={true}
										searchEnabled={true}
										searchMode="startswith"
										value={this.state.AssetServiceLifeID}
										onValueChanged={this.onAssetServiceLifeChanged}
									/>
								</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">Asset Life Cycle</div>
								<NumberBox
									className="dx-field-value"
									value={this.state.AssetLifeCycle}
									onValueChanged={this.onAssetLifeCycleChanged}
									format={FORMAT.WHOLE_NUMBER}
								>
									<Validator name="Asset Life Cycle">
										<RangeRule
											min={this.state.AssetLifeCycleMinimum}
											max={this.state.AssetLifeCycleMaximum}
										/>
									</Validator>
								</NumberBox>
							</div>
							<div className={`dx-field ${showNewLeaseView && 'dx-state-invisible'}`}>
								<div className="dx-field-label">Unit Replacement Value</div>
								<AssetAutoNumberBox
									className="dx-field-value"
									filterByManufacturerValue={this.state.AssetManufacturerName}
									filterBySubTypeValue={this.state.AssetSubTypeName}
									filterByModelNameValue={this.state.AssetModelName}
									value={this.state.UnitReplacementValue}
									valueExpr="unitReplacementValue"
									onValueChanged={this.onUnitReplacementValueChanged}
									overrideValue={this.overrideUnitReplacementValue}
									>
										<Validator name="Unit Replacement Value">
											<CustomRule validationCallback={greaterThanZeroIfNotLeased} message="Unit Replacement Value needs to be a positive number." />
										</Validator>
								</AssetAutoNumberBox> 
							</div>
							<div className={`dx-field ${showNewLeaseView && 'dx-state-invisible'}`}>
								<div className="dx-field-label">Aggregate Replacement Value</div>
								<NumberBox
									className="dx-field-value"
									value={this.state.AggregateReplacementValue}
									readOnly={true}
								/>
							</div>
							<div className={`dx-field ${showNewLeaseView && 'dx-state-invisible'}`}>
								<div className="dx-field-label">Initial RY, Auto</div>
								<NumberBox
									className="dx-field-value"
									value={this.state.InitialRYAuto}
									onValueChanged={this.onInitialRYAutoChanged}
									readOnly={true}
								/>
							</div>
							<div className={`dx-field ${showNewLeaseView && 'dx-state-invisible'}`}>
								<div className="dx-field-label">Initial RY, Manual</div>
								<NumberBox
									className="dx-field-value"
									value={this.state.InitialRYManual}
									onValueChanged={this.onInitialRYManualChanged}
									format={FORMAT.WHOLE_NUMBER}
								>
									<Validator name="Initial RY, Manual">
										<CustomRule validationCallback={greaterThanZeroIfNotLeased} message="Initial RY, Auto needs to be a positive number." />
									</Validator>
								</NumberBox>
							</div>
							<div className={`dx-field ${showNewLeaseView && 'dx-state-invisible'}`}>
								<div className="dx-field-label">Last RY Cycle in Service</div>
								<NumberBox
									className="dx-field-value"
									value={this.state.LastRYCycle}
									onValueChanged={this.onLastRYCycleChanged}
									format={FORMAT.WHOLE_NUMBER}
								>
									<Validator name="Last RY Cycle in Service">
										<CustomRule validationCallback={greaterThanZeroIfNotLeased} message="Last RY Cycle in Service needs to be a positive number." />
									</Validator>
								</NumberBox>
							</div>							
							<div className="dx-field">
								<div className="dx-field-label">Ledger Id</div>
								<TextBox
									className="dx-field-value"
									placeholder="Ledger Id..."
									value={this.state.LedgerId}
									onValueChanged={this.onLedgerIdChanged}
								/>
							</div>
						</div>
						<div className="dx-fieldset" id="notes-container">
							<div className="dx-field">
								<div className="dx-field-label">General Notes or Comments</div>
								<TextArea
									className="dx-field-value"
									height={80}
									defaultValue=""
									value={this.state.Notes}
									onValueChanged={this.onGeneralNotesChanged}
								/>
							</div>
						</div>
						<div className={`dx-fieldset ${!showNewLeaseView && 'dx-state-invisible'}`}>
							<div className="dx-field">
								<div className="dx-field-label">
									Current Lease Term Start
								</div>
								<DateBox
									className="dx-field-value"
									value={this.state.leaseStart || undefined}
									onValueChanged={this.onLeaseStartChanged}
									openOnFieldClick={true}
								>
									<Validator>
										<CustomRule validationCallback={requiredIfLeased} message="Current Lease Term Start is required." />
									</Validator>
								</DateBox>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">
									Lease Term in Months
								</div>
								<NumberBox
									className="dx-field-value"
									value={this.state.leaseTerm || undefined}
									onValueChanged={this.onLeaseTermChanged}
									format={FORMAT.WHOLE_NUMBER}
								>
									<Validator>
										<CustomRule validationCallback={requiredIfLeased} message="Lease Term in Months is required." />
										<CustomRule validationCallback={greaterThanZeroIfLeased} message="Lease Term needs to be a positive number."/>
										<CustomRule validationCallback={validateLeaseTermMaxValue} message={`Lease Term needs to be less than ${LEASE_TERM_PARAMS.MAX}.`}/>
									</Validator>
								</NumberBox>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">
									Current Monthly Payment
								</div>
								<NumberBox
									className="dx-field-value"
									value={
										this.state.leaseMonthlyPayment || undefined
									}
									format={FORMAT.THOUSANDS_AND_DECIMAL}
									onValueChanged={this.onLeaseMonthlyPaymentChanged}
								>
									<Validator>
										<CustomRule validationCallback={requiredIfLeased} message="Current Monthly Payment is required." />
										<CustomRule validationCallback={greaterThanZeroIfLeased} message="Current Monthly Payment needs to be a positive number."/>
									</Validator>
								</NumberBox>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">
									Dollar Buyout Amount
									<DollarBuyoutInfo toolTipPosition={"right"} iconClassName='ml-1' />
								</div>
								<NumberBox
									className="dx-field-value"
									value={
										this.state.leaseDollarBuyoutAmount || undefined
									}
									format={FORMAT.THOUSANDS_AND_DECIMAL}
									onValueChanged={this.onLeaseDollarBuyoutChanged}
								>
									<Validator>
										<CustomRule validationCallback={greaterThanZeroIfLeased} message="Dollar Buyout Amount needs to be a positive number."/>
									</Validator>
								</NumberBox>
							</div>
							<Form labelLocation="top" colCount={4}>
								<SimpleItem
									dataField="Current Lease Term End"
								>
									<DateBox
										readOnly={true}
										value={
											this.state.leaseEnd || undefined
										}
									/>
								</SimpleItem>
								<SimpleItem
									dataField="Total Yearly Lease Payments"
								>
									<NumberBox
										readOnly={true}
										format={FORMAT.THOUSANDS_AND_DECIMAL}
										value={
											this.state.leaseYearlyPayment ||
											undefined
										}
									/>
								</SimpleItem>
								<SimpleItem
									dataField="Total Lease Payments"
								>
									<NumberBox
										readOnly={true}
										format={FORMAT.THOUSANDS_AND_DECIMAL}
										value={
											this.state.leaseTotalPayment ||
											undefined
										}
									/>
								</SimpleItem>
								<SimpleItem
									dataField="Current Lease Term Start Year"
								>
									<NumberBox
										readOnly={true}
										value={leaseStartYear}
									/>
								</SimpleItem>
							</Form>
						</div>
						<div className="dx-fieldset">
							{IsLeased && isLegacyLease && 
								<div className="dx-field">
									<div className="text-info">The asset being edited is a legacy lease asset and the new lease form cannot be used.</div>
								</div>
							}
							<div className="dx-field">
								<ValidationSummary id="summary" />
							</div>
							<div className="buttons-entry-crs" width="500">
								<div className="buttons">
									<div className="buttons-column">
										<div className="online">
											<Button
												width={250}
												text="Save - Start New Asset"
												type="success"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSaveNewAssetClick}
											/>
										</div>
										<div className="offline">
											<Button
												width="auto"
												text="Save - Start New Asset (Offline)"
												type="success"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSaveNewAssetClickOffline}
											/>
										</div>
									</div>
									<div className="buttons-column">
										<div className="online">
											<Button
												width={250}
												text="Save - Start New Room"
												type="default"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSaveStartNewRoomClick}
											/>
										</div>
										<div className="offline">
											<Button
												width={250}
												text="Save - Start New Room"
												type="default"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSaveStartNewRoomClickOffline}
											/>
										</div>
									</div>
									<div className="buttons-column">
										<div className="online">
											<Button
												width={250}
												text="Save - Keep ACC & AT"
												type="normal"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSaveCodingTypeClick}
											/>
										</div>
										<div className="offline">
											<Button
												width="auto"
												text="Save - Keep ACC & AT (Offline)"
												type="normal"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSaveCodingTypeClickOffline}
											/>
										</div>
									</div>
									<div className="buttons-column">
										<div className="online">
											<Button
												width={250}
												text="Save & Duplicate"
												type="normal"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSavingDuplicateClick}
												icon="repeat"
											/>
										</div>
										<div className="offline">
											<Button
												width="auto"
												text="Save & Duplicate"
												type="normal"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSavingDuplicateClickOffline}
												icon="repeat"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					</ValidationGroup>

					<Popup
						width={660}
						height={540}
						showTitle={true}
						dragEnabled={false}
						closeOnOutsideClick={true}
						visible={this.state.NewRoomPopupVisible}
						onHiding={this.handleRoomPopupHidden}
						onShowing={this.handleRoomPopupShowing}
					>
						<div className="dx-form">
							<div className="dx-fieldset">
								<div className="dx-fieldset-header">Enter New Room</div>
								<div className="dx-field">
									<div className="dx-field-label">Structure</div>
									<SelectBox
										className="dx-field-value"
										dataSource={studyStructureLookup}
										placeholder="Choose Structure..."
										valueExpr="id"
										displayExpr="name"
										value={this.state.NewRoomStructureId}
										onValueChanged={this.onNewRoomStructureChanged}>
									</SelectBox>
								</div>
								<div className="dx-field">
									<div className="dx-field-label">Room Name</div>
									<TextBox
										className="dx-field-value"
										defaultValue="Room Name..."
										value={this.state.NewRoomName}
										onValueChanged={this.onNewRoomNameChanged}
									/>
								</div>
								<div className="dx-field">
									<div className="dx-field-label">Room Type</div>
									<SelectBox
										className="dx-field-value"
										dataSource={roomType}
										placeholder="Room Type..."
										valueExpr="id"
										displayExpr="name"
										value={this.state.NewRoomType}
										onValueChanged={this.onNewRoomTypeChanged}
									/>
								</div>
								<div className="buttons-column">
									<div>
										<div className="online">
											<Button
												width={200}
												text="Save"
												type="normal"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSaveNewRoomClick}
											/>
										</div>
										<div className="offline">
											<Button
												width={200}
												text="Save (Offline)"
												type="normal"
												stylingMode="contained"
												useSubmitBehavior={false}
												onClick={this.onSaveNewRoomClickOffline}
											/>
										</div>
									</div>
								</div>

							</div>
						</div>
					</Popup>
				</form>
				</>
			</Container>
		);
	}

	onNewAssetClick(form) {
		var formValues = this.state;

		var jsonString = JSON.stringify(formValues);
		return fetch('/api/assets/', {
			method: "POST",
			body: jsonString,
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(
			this.clearNewAsset()
		).then(
			notify({
				message: 'You have submitted the form and are ready to add a new asset.',
				position: {
					my: 'center top',
					at: 'center top'
				}
			}, 'success', 3000)
		).then(handleErrors);
	}

	onNewRoomClick(form, e) {

		var formValues = this.state;

		var jsonString = JSON.stringify(formValues);
		return fetch('/api/assets/', {
			method: "POST",
			body: jsonString,
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(
			this.clearNewRoom()
		).then(
			notify({
				message: 'You have submitted the form. Choose a new room before adding the new asset.',
				position: {
					my: 'center top',
					at: 'center top'
				}
			}, 'success', 3000)
		).then(handleErrors);
	}

	handleRoomPopupHidden() {
		this.setState({
			NewRoomPopupVisible: false
		});
	}

	handleRoomPopupShowing() {
		let currentStructure = this.state.StructureId;
		//console.log('Current', currentStructure);
		this.setState({
			NewRoomStructureId: currentStructure
		});
		//console.log('State', this.state.NewRoomStructureId);
	}

	onRoomChanged(e) {
		let roomId = e.value;
		let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == roomId);
		let assetCount = assetsThisRoom.length;
		this.setState({
			StudyRoomId: roomId,
			AssetCountThisRoom: assetCount,
			AssetsThisRoom: assetsThisRoom
		});
		this.clearNewAsset();
	}
	onStructureChanged(e) {
		let chosenStructureId = e.value;
		this.setState({
			StructureId: chosenStructureId
		});
		let newList = studyRoomsLookup.filter(room => room.studystructureid == chosenStructureId);
		this.setState({
			studyRoomsLookupFiltered: newList,
			RoomCount: newList.length,
			AssetCountThisRoom: 0,
			AssetsThisRoom: []
		});
		this.clearNewAsset();
	}

	onAssetCodingClassChanged(e) {
		this.setState({
			AssetCodingClassId: e.value
		});
	}

	onAssetTypeChanged(e) {
		this.setState({
			AssetTypeId: e.value,
			AssetSubTypeName: e.value == 9 ? 'Flooring' : this.state.AssetSubTypeName
		});
	}

	disableOverrides(){
		this.setState({overrideDisabled: true})	
	}

	onAssetSubtypeChanged(e) {
		const {value} = e
		this.setState(state=>({
			AssetSubTypeName: value,
			...(!state.overrideDisabled && {AssetModelName: "", Description: "", overrideDisabled: false})
		}));
	}

	onAssetManufacturerNameChanged(e) {
		const {value} = e
		this.setState(state=>({
			AssetManufacturerName: value,
			...(!state.overrideDisabled  && {AssetModelName: "", Description: "", overrideDisabled: false})
		}));
	}

	onAssetModelNameChanged(e) {
		const {value} = e
		this.setState({
			AssetModelName: value
		});
	}

	onAssetLeaseStatusChanged(e) {
		const isLeased = e.value
		this.setState({
			IsLeased: isLeased,
		});
	}

	onAssetDetailDescriptionChanged(e) {
		this.setState({
			Description: e.value
		});
	}

	onQuantityChanged(e) {
		const {value} = e
		this.setState(state=>({
			Qty: value,
			AggregateReplacementValue: calculateAggregateReplacementValue(state.UnitReplacementValue, value)
		}));
	}

	onLedgerIdChanged(e) {
		this.setState({
			LedgerId: e.value
		});
	}

	onUomChanged(e) {
		this.setState({
			UnitOfMeasureId: e.value
		});
	}

	onYearInstalledChanged(e) {
		this.setState({
			YearInstalled: e.value,
			InitialRYAuto: e.value + this.state.AssetLifeCycle == 0 ? '' : e.value + this.state.AssetLifeCycle

		});
	}

	onAssetServiceLifeChanged(e) {
		this.setState({
			AssetServiceLifeID: e.value
		});

		if (e.value) {
			if (e.component.option("text").startsWith("to")) {
				const assetLifeCycle = parseInt(e.component.option("text").replace("to", "").trim());
				let [minimumLifeCycle, maximumLifecycle] = [assetLifeCycle, assetLifeCycle];
				this.setState({
					AssetLifeCycle: assetLifeCycle,
					AssetLifeCycleMinimum: parseInt(minimumLifeCycle),
					AssetLifeCycleMaximum: parseInt(maximumLifecycle),
					InitialRYAuto: e.value + this.state.YearInstalled == 0 ? '' : assetLifeCycle + this.state.YearInstalled
				});
			} else {
				const minMaxRange = e.component.option("text").match(/\d+/g); // Get a list of all integers
				minMaxRange.sort(function (x, y) {         // Sort list
					return x - y;
				});
				let [minimumLifeCycle, maximumLifecycle] = [minMaxRange[0], minMaxRange[1]];
				this.setState({
					AssetLifeCycleMinimum: parseInt(minimumLifeCycle),
					AssetLifeCycleMaximum: parseInt(maximumLifecycle)
				});
			}
		} else {
			this.setState({
				AssetLifeCycleMinimum: 0,
				AssetLifeCycleMaximum: 100
			});
		}
	}

	onAssetLifeCycleChanged(e) {
		this.setState(state=>({
			AssetLifeCycle: e.value,
			InitialRYAuto: e.value + state.YearInstalled === 0 ? '' : e.value + state.YearInstalled
		}));
	}

	overrideUnitReplacementValue(newValue) {
		this.setState(state=>
			state.overrideDisabled ? 
			({overrideDisabled: false}) : 
			({UnitReplacementValue: newValue,
			AggregateReplacementValue: calculateAggregateReplacementValue(newValue, state.Qty)
		}));
	}

	onUnitReplacementValueChanged(newValue) {
		this.setState(state=>({
			UnitReplacementValue: newValue,
			AggregateReplacementValue: calculateAggregateReplacementValue(newValue, state.Qty),
			...(state.overrideDisabled && {overrideDisabled: false})
		}));
	}

	onInitialRYAutoChanged(e) {
		this.setState({
			InitialRYAuto: e.value
		});
	}

	onInitialRYManualChanged(e) {
		this.setState({
			InitialRYManual: e.value
		});
	}

	onLastRYCycleChanged(e) {
		this.setState({
			LastRYCycle: e.value
		});
	}

	onGeneralNotesChanged(e) {
		this.setState({
			Notes: e.value
		});
	}

	onQuantityFocusOut(e) {
		try {
			const qtyResult = math.evaluate(this.state.Qty);
			const roundedQtyResult = math.round(qtyResult)
			this.setState({
				Qty: roundedQtyResult,
				AggregateReplacementValue: calculateAggregateReplacementValue(this.state.UnitReplacementValue, roundedQtyResult)
			})
		} catch (err) {
			// error already propagated on focus out through validator.

		}
	}

	clearNewRoom = () => {
		this.setState({
			StudyRoomId: "",
			AssetCodingClassId: "",
			AssetTypeId: "",
			AssetSubTypeId: "",
			AssetManufacturerName: "",
			AssetModelName: "",
			IsLeased: false,
			AssetSubTypeName: "",
			Description: "",
			Qty: "",
			UnitOfMeasureId: "",
			YearInstalled: null,
			AssetServiceLifeID: "",
			AssetLifeCycle: null,
			UnitReplacementValue: null,
			AggregateReplacementValue: null,
			InitialRYAuto: null,
			InitialRYManual: null,
			LastRYCycle: null,
			Notes: "",
			EditAssetId: 0,
			AssetLifeCycleMinimum: 0,
			AssetLifeCycleMaximum: 100,
			LedgerId: undefined,

			//Clear Leased Asset Details
			leaseStart: undefined,
			leaseTerm: undefined,
			leaseEnd: undefined,
			leaseDollarBuyoutAmount: undefined,
			leaseMonthlyPayment: undefined,
			leaseYearlyPayment: undefined,
			leaseTotalPayment: undefined,
			isLegacyLease: false
		})
	}

	clearNewAsset = () => {
		this.setState({
			AssetCodingClassId: "",
			AssetTypeId: "",
			AssetSubTypeId: "",
			AssetManufacturerName: "",
			AssetModelName: "",
			IsLeased: false,
			AssetSubTypeName: "",
			Description: "",
			Qty: "",
			UnitOfMeasureId: "",
			YearInstalled: null,
			AssetServiceLifeID: "",
			AssetLifeCycle: null,
			UnitReplacementValue: null,
			AggregateReplacementValue: null,
			InitialRYAuto: null,
			InitialRYManual: null,
			LastRYCycle: null,
			Notes: "",
			EditAssetId: 0,
			AssetLifeCycleMinimum: 0,
			AssetLifeCycleMaximum: 100,
			LedgerId: undefined,

			//Clear Leased Asset Details
			leaseStart: undefined,
			leaseTerm: undefined,
			leaseEnd: undefined,
			leaseDollarBuyoutAmount: undefined,
			leaseMonthlyPayment: undefined,
			leaseYearlyPayment: undefined,
			leaseTotalPayment: undefined,
			isLegacyLease: false
		})
	}

	clearNewCodingType = () => {
		this.setState({
			AssetSubTypeId: "",
			AssetManufacturerName: "",
			AssetModelName: "",
			IsLeased: false,
			AssetSubTypeName: "",
			Description: "",
			Qty: "",
			UnitOfMeasureId: "",
			YearInstalled: null,
			AssetServiceLifeID: "",
			AssetLifeCycle: null,
			UnitReplacementValue: null,
			AggregateReplacementValue: null,
			InitialRYAuto: null,
			InitialRYManual: null,
			LastRYCycle: null,
			Notes: "",
			EditAssetId: 0,
			AssetLifeCycleMinimum: 0,
			AssetLifeCycleMaximum: 100,
			LedgerId: undefined,

			//Clear Leased Asset Details
			leaseStart: undefined,
			leaseTerm: undefined,
			leaseEnd: undefined,
			leaseDollarBuyoutAmount: undefined,
			leaseMonthlyPayment: undefined,
			leaseYearlyPayment: undefined,
			leaseTotalPayment: undefined,
			isLegacyLease: false
		})
	}

	clearSaveDuplicate = () => {
		this.setState({
			EditAssetId: 0
		})
	}

	onSaveNewAssetClick = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			let newAssetStudyRoomId = this.state.StudyRoomId;
			var formValues = this.state;
			addAssetAsync(formValues).then(syncassets => {
				this.clearNewAsset();
				let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == newAssetStudyRoomId);
				let assetCount = assetsThisRoom.length;
				this.setState({
					AssetCountThisRoom: assetCount,
					AssetsThisRoom: assetsThisRoom
				});
				this.topOfForm.current.scrollIntoView({
					behavior: "smooth",
					block: "nearest"
				});
			});
        }
	}

	populateEdit = (data) => {
		this.setState({
			EditAssetId: data.id,
			AssetCodingClassId: data.assetCodingClassId,
			AssetTypeId: data.assetTypeId,
			AssetSubTypeName: data.assetSubTypeName,
			AssetManufacturerName: data.assetManufacturerName,
			AssetModelName: data.assetModelName,
			IsLeased: data.isLeased,
			Description: data.description,
			Qty: data.qty,
			UnitOfMeasureId: data.unitOfMeasureId,
			YearInstalled: data.yearInstalled,
			AssetServiceLifeID: data.assetServiceLifeId,
			AssetLifeCycle: data.assetLifeCycle,
			UnitReplacementValue: data.unitReplacementValue,
			AggregateReplacementValue: data.aggregateReplacementValue,
			InitialRYAuto: data.initialRYAuto,
			InitialRYManual: data.initialRYManual,
			LastRYCycle: data.lastRYCycle,
			Notes: data.notes,
			LedgerId: data.ledgerId,

			//Lease Details
			leaseStart: data.leaseStart,
			leaseTerm: data.leaseTerm,
			leaseEnd: data.leaseEnd,
			leaseDollarBuyoutAmount: data.leaseDollarBuyoutAmount,
			leaseMonthlyPayment: data.leaseMonthlyPayment,
			leaseYearlyPayment: data.leaseYearlyPayment,
			leaseTotalPayment: data.leaseTotalPayment,
			isLegacyLease: data.isLegacyLease
		});
	}

	onSaveNewAssetClickOffline = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			let newAssetStudyRoomId = this.state.StudyRoomId;
			var formValues = this.state;

			var min = Math.min.apply(null, studyAssetsLookup.map(item => item.id));
			let newAssetId = generateNewOfflineId(min);
			if (this.state.EditAssetId > 0) {
				newAssetId = this.state.EditAssetId;
			}
			addToStudyAssetsLookup(newAssetId, formValues.AssetSubTypeName, newAssetStudyRoomId, formValues.CurrentUser.name, formValues.AssetManufacturerName, formValues.Description);
			addToUnsyncedAssets(newAssetId, formValues);
			this.clearNewAsset();
			let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == newAssetStudyRoomId);
			let assetCount = assetsThisRoom.length;
			let unsyncedAssetCount = this.state.UnsyncedAssetCount + 1;
			this.setState({
				AssetCountThisRoom: assetCount,
				AssetsThisRoom: assetsThisRoom,
				DBSyncStatus: "NOT SYNCED",
				UnsyncedAssetCount: unsyncedAssetCount
			});
			this.topOfForm.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest"
			});
		}
	}

	onSaveStartNewRoomClick = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			var formValues = this.state;
			addAssetAsync(formValues).then(syncassets => {
				this.clearNewAsset();
				this.setState({
					AssetCountThisRoom: 0,
					AssetsThisRoom: [],
					StudyRoomId: ""
				});
				this.topOfForm.current.scrollIntoView({
					behavior: "smooth",
					block: "nearest"
				});
			});
			this.topOfForm.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest"
			});
		}
	}
	onSaveStartNewRoomClickOffline = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			let newAssetStudyRoomId = this.state.StudyRoomId;
			var formValues = this.state;
			var min = Math.min.apply(null, studyRoomsLookup.map(item => item.id));
			let newAssetId = generateNewOfflineId(min);
			if (this.state.EditAssetId > 0) {
				newAssetId = this.state.EditAssetId;
			}
			addToStudyAssetsLookup(newAssetId, formValues.AssetSubTypeName, newAssetStudyRoomId, formValues.CurrentUser.name, formValues.AssetManufacturerName, formValues.Description);
			addToUnsyncedAssets(newAssetId, formValues);
			this.clearNewAsset();
			let unsyncedAssetCount = this.state.UnsyncedAssetCount + 1;
			this.setState({
				AssetCountThisRoom: 0,
				AssetsThisRoom: [],
				StudyRoomId: "",
				DBSyncStatus: "NOT SYNCED",
				UnsyncedAssetCount: unsyncedAssetCount
			});
			this.topOfForm.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest"
			});
		}
	}

	onSaveNewRoomClick = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			let srt = studyRoomTemplate;
			srt.name = this.state.NewRoomName;
			srt.studyStructureId = this.state.NewRoomStructureId;
			srt.roomTypeId = this.state.NewRoomType;
			addRoomAsync(srt).then(syncdropdowns => {
				let newRoomId = syncdropdowns;
				this.setState({
					StructureId: srt.studyStructureId,
				});
				let newList = studyRoomsLookup.filter(room => room.studystructureid == this.state.StructureId);
				this.setState({
					studyRoomsLookupFiltered: newList,
					RoomCount: newList.length,
					StudyRoomId: newRoomId,
					NewRoomName: "",
					NewRoomPopupVisible: false
				});
			});
		}
	}

	onSaveNewRoomClickOffline = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			let srt = {
				name: this.state.NewRoomName,
				studyStructureId: this.state.NewRoomStructureId,
				roomTypeId: this.state.NewRoomType,
				CurrentUser: this.state.CurrentUser,
				UnsyncedAssets: []
			};
			var min = Math.min.apply(null, studyRoomsLookup.map(item => item.id));
			let newRoomId = generateNewOfflineId(min);
			srt.id = newRoomId;
			studyRoomsLookup.push({ 'id': srt.id, 'name': srt.name, 'studystructureid': srt.studyStructureId });
			addToUnsyncedRooms(srt);
			this.setState({
				StructureId: srt.studyStructureId,
			});
			let newList = studyRoomsLookup.filter(room => room.studystructureid == this.state.StructureId);
			this.setState({
				studyRoomsLookupFiltered: newList,
				RoomCount: newList.length
			});
			this.setState({
				StudyRoomId: newRoomId
			});
			this.setState({
				NewRoomName: "",
				NewRoomPopupVisible: false
			});
			let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == newRoomId);
			let assetCount = assetsThisRoom.length;
			let unsyncedRoomCount = UnsyncedRooms.length;
			this.setState({
				AssetCountThisRoom: assetCount,
				AssetsThisRoom: assetsThisRoom,
				DBSyncStatus: "NOT SYNCED",
				UnsyncedRoomCount: unsyncedRoomCount
			});
		}
	}

	onSaveCodingTypeClick = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			let newAssetStudyRoomId = this.state.StudyRoomId;
			var formValues = this.state;
			addAssetAsync(formValues).then(syncassets => {
				this.clearNewCodingType();
				let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == newAssetStudyRoomId);
				let assetCount = assetsThisRoom.length;
				this.setState({
					AssetCountThisRoom: assetCount,
					AssetsThisRoom: assetsThisRoom
				});
				this.topOfForm.current.scrollIntoView({
					behavior: "smooth",
					block: "nearest"
				});
			});
		}
	}

	onSaveCodingTypeClickOffline = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			let newAssetStudyRoomId = this.state.StudyRoomId;
			var formValues = this.state;
			var min = Math.min.apply(null, studyAssetsLookup.map(item => item.id));
			let newAssetId = generateNewOfflineId(min);
			if (this.state.EditAssetId > 0) {
				newAssetId = this.state.EditAssetId;
			}
			addToStudyAssetsLookup(newAssetId, formValues.AssetSubTypeName, newAssetStudyRoomId, formValues.CurrentUser.name, formValues.AssetManufacturerName, formValues.Description);
			addToUnsyncedAssets(newAssetId, formValues);
			this.clearNewCodingType();
			let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == newAssetStudyRoomId);
			let assetCount = assetsThisRoom.length;
			let unsyncedAssetCount = this.state.UnsyncedAssetCount + 1;
			this.setState({
				AssetCountThisRoom: assetCount,
				AssetsThisRoom: assetsThisRoom,
				DBSyncStatus: "NOT SYNCED",
				UnsyncedAssetCount: unsyncedAssetCount
			});
			this.topOfForm.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest"
			});
		}
	}

	onSavingDuplicateClick = (e) => {
			const { isValid } = e.validationGroup.validate();
			if (isValid) {
			let newAssetStudyRoomId = this.state.StudyRoomId;
			var formValues = this.state;
			addAssetAsync(formValues).then(syncassets => {
				this.clearSaveDuplicate();
				let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == newAssetStudyRoomId);
				let assetCount = assetsThisRoom.length;
				this.setState({
					AssetCountThisRoom: assetCount,
					AssetsThisRoom: assetsThisRoom
				});
				this.topOfForm.current.scrollIntoView({
					behavior: "smooth",
					block: "nearest"
				});
			});
		}
	}

	onSavingDuplicateClickOffline = (e) => {
		const { isValid } = e.validationGroup.validate();
        if (isValid) {
			let newAssetStudyRoomId = this.state.StudyRoomId;
			var formValues = this.state;
			var min = Math.min.apply(null, studyAssetsLookup.map(item => item.id));
			let newAssetId = generateNewOfflineId(min);
			if (this.state.EditAssetId > 0) {
				newAssetId = this.state.EditAssetId;
			}
			addToStudyAssetsLookup(newAssetId, formValues.AssetSubTypeName, newAssetStudyRoomId, formValues.CurrentUser.name, formValues.AssetManufacturerName, formValues.Description);
			addToUnsyncedAssets(newAssetId, formValues);
			this.clearSaveDuplicate();
			let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == newAssetStudyRoomId);
			let assetCount = assetsThisRoom.length;
			let unsyncedAssetCount = this.state.UnsyncedAssetCount + 1;
			this.setState({
				AssetCountThisRoom: assetCount,
				AssetsThisRoom: assetsThisRoom,
				DBSyncStatus: "NOT SYNCED",
				UnsyncedAssetCount: unsyncedAssetCount
			});
			this.topOfForm.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest"
			});
		}
	}

	parseServiceLife = (serviceLife) => {
		var list = serviceLife.match(/\d+/g); // Get a list of all integers
		list.sort(function (x, y) {         // Sort list
			return x - y;
		});
	}


	onNewRoomButtonClick = () => {
		// console.log('this.state.NewRoomStructureId', this.state.NewRoomStructureId);
		/*let currentStructureId = this.state.StructureId;
		//console.log(this.state.NewRoomStructureId);
		if (Number.isInteger(currentStructureId)) {*/
		this.setState({
			NewRoomPopupVisible: true
		});
		/*}
		else {
			const type = 'error';
			const text = 'You must select a structure to add a room';
			notify(text, type, 600);
		}*/
	}

	SyncRoomsWithDB = () => {
		//console.log("Rooms to Sync:", UnsyncedRooms.length);
		//console.log("UnsyncedRooms:", UnsyncedRooms);
		//console.log("UnsyncedAssets:", UnsyncedAssets);

		//console.log("Assets to Sync:", UnsyncedAssets.length);

		if (UnsyncedAssets.length > 0) {
			// handleDBSyncButtonClickedAssets
			// Copy the array so we can remove from the original as we sync
			let UnsyncedAssetsToIterate = UnsyncedAssets;
			UnsyncedAssetsToIterate.forEach(asset => {
				let tempAssetId = asset.Id;
				if (tempAssetId < 0) {
					asset.Id = 0;
				}
				//console.log("Save ID:", asset.Id);
				//console.log("	Name:", asset.AssetSubTypeName);
				//console.log("	RoomID:", asset.StudyRoomId);
				//console.log("	Asset:", asset);
				addAssetAsync(asset).then(syncassets => {
					let newAssetId = syncassets;
					// studyAssetsLookup should have the newly added item.  Remove the placeholder with the negative id.
					let indexToRemove = studyAssetsLookup.findIndex(asset => asset.id === tempAssetId);
					studyAssetsLookup.splice(indexToRemove, 1);
					/*let assetsThisRoom = studyAssetsLookup.filter(assets => assets.studyroomid == asset.StudyRoomId);
					let assetCount = assetsThisRoom.length;
					this.setState({
						AssetCountThisRoom: assetCount,
						AssetsThisRoom: assetsThisRoom
					});*/
					// Remove from the UnsyncedAssets array
					let indexToRemoveUA = UnsyncedAssets.findIndex(asset => asset.id === tempAssetId);
					UnsyncedAssets.splice(indexToRemoveUA, 1);
				});
			});
		}

		if (UnsyncedRooms.length > 0) {
			// Sync Rooms with DB
			let UnsyncedRoomsToIterate = UnsyncedRooms;
			UnsyncedRoomsToIterate.forEach(room => {
				let tempRoomId = room.id;
				room.id = 0;
				addRoomAsync(room).then(syncrooms => {
					let newRoomId = syncrooms;
					// studyRoomsLookup should have the newly added item.  Remove the placeholder with the negative id.
					let indexToRemove = studyRoomsLookup.findIndex(r => r.id === tempRoomId);
					studyRoomsLookup.splice(indexToRemove, 1);
					// Update any UnsyncedAssets with the new room id.
					room.UnsyncedAssets.forEach(asset => {
						if (asset.StudyRoomId == tempRoomId) {
							//console.log("Match:", tempRoomId);
							//console.log("newRoomId:", newRoomId);
							asset.Id = 0;
							asset.StudyRoomId = newRoomId;
							addAssetAsync(asset);
						}
						//console.log("asset:", asset);
					});

					// Remove from the UnsyncedAssets array
					let indexToRemoveUR = UnsyncedRooms.findIndex(room => room.id === tempRoomId);
					UnsyncedRooms.splice(indexToRemoveUR, 1);

					/*let assetsThisRoom = studyRoomsLookup.filter(assets => assets.studyroomid == r.StudyRoomId);
					let assetCount = assetsThisRoom.length;
					this.setState({
						AssetCountThisRoom: assetCount,
						AssetsThisRoom: assetsThisRoom
					});
					*/
				});
			});
			UnsyncedRooms.length = 0;

		}
	}
}

const studyStructures = createStore({
	key: 'Id',
	loadUrl: '/api/studystructures',
	onBeforeSend: (method, ajaxOptions) => {
		ajaxOptions.xhrFields = { withCredentials: true };
	},
	onLoaded: () => { }
});

const studyRooms = createStore({
	key: 'Id',
	loadUrl: '/api/studyrooms',
	onBeforeSend: (method, ajaxOptions) => {
		ajaxOptions.xhrFields = { withCredentials: true };
	}
});

const studyAssets = createStore({
	key: 'Id',
	loadUrl: '/api/assets/',
	onBeforeSend: (method, ajaxOptions) => {
		ajaxOptions.xhrFields = { withCredentials: true };
	}
});


function loadStudyStructureLookup() {
	studyStructures.load()
		.then(
			(data) => {
				data.forEach(element => {
					var itemIndex = studyStructureLookup.findIndex(x => x.id === element.id);
					if (itemIndex == -1) {
						//not found, add new
						studyStructureLookup.push({ 'id': element.id, 'name': element.name });
					} else {
						//found, update name
						studyStructureLookup[itemIndex].name = element.name;
					}
				});

			}
		)
}

function loadStudyRoomsLookup() {
	studyRooms.load()
		.then(
			(data) => {
				data.forEach(element => {
					var itemIndex = studyRoomsLookup.findIndex(x => x.id === element.id);
					if (itemIndex == -1) {
						//not found, add new
						studyRoomsLookup.push({ 'id': element.id, 'name': element.name, 'studystructureid': element.studyStructureId });
					} else {
						//found, update name
						studyRoomsLookup[itemIndex].name = element.name;
					}
				});

			}
		)
}

function loadStudyAssetsLookup() {
	studyAssets.load()
		.then(
			(data) => {
				data.forEach(element => addToStudyAssetsLookup(element.id, element.assetSubTypeName, element.studyRoomId, element.createdBy, element.assetManufacturerName, element.description));
			}
		)
}

function addToStudyAssetsLookup(id, name, studyroomid, createdby, manufacturer, description) {
	let usecreatedby = "";
	if (createdby.length > 0) {
		usecreatedby = createdby.split("@")[0];
	}
	var itemIndex = studyAssetsLookup.findIndex(x => x.id === id);
	if (itemIndex == -1) {
		studyAssetsLookup.push({ 'id': id, 'name': name, 'studyroomid': studyroomid, 'createdby': usecreatedby, 'manufacturer': manufacturer, 'description': description });
	} else {
		//found, update name
		studyAssetsLookup[itemIndex].name = name;
		studyAssetsLookup[itemIndex].description = description;
	}

	var subtypeIndex = assetSubtypeAutocomplete.findIndex(x => x.name === name);
	if (subtypeIndex == -1) {
		assetSubtypeAutocomplete.push({ 'name': name });
	}
	var manufacturerIndex = assetManufacturerAutocomplete.findIndex(x => x.manufacturer === manufacturer);
	if (manufacturerIndex == -1) {
		assetManufacturerAutocomplete.push({ 'manufacturer': manufacturer });
	}
}

function addToUnsyncedRooms(unsyncedRoom) {
	UnsyncedRooms.push(unsyncedRoom);
}

function addToUnsyncedAssets(newId, formvalues) {
	let saveAsset = {
		Id: newId,
		StudyRoomId: formvalues.StudyRoomId,
		AssetCodingClassId: formvalues.AssetCodingClassId,
		AssetTypeId: formvalues.AssetTypeId,
		AssetSubTypeId: formvalues.AssetSubTypeId,
		AssetManufacturerName: formvalues.AssetManufacturerName,
		AssetModelName: formvalues.AssetModelName,
		IsLeased: formvalues.IsLeased,
		AssetSubTypeName: formvalues.AssetSubTypeName,
		Description: formvalues.Description,
		Qty: formvalues.Qty,
		UnitOfMeasureId: formvalues.UnitOfMeasureId,
		YearInstalled: formvalues.YearInstalled,
		AssetServiceLifeID: formvalues.AssetServiceLifeID,
		AssetLifeCycle: formvalues.AssetLifeCycle,
		UnitReplacementValue: formvalues.UnitReplacementValue,
		AggregateReplacementValue: formvalues.AggregateReplacementValue,
		InitialRYAuto: formvalues.InitialRYAuto,
		InitialRYManual: formvalues.InitialRYManual,
		LastRYCycle: formvalues.LastRYCycle,
		Notes: formvalues.Notes,
		CurrentUser: formvalues.CurrentUser,

		//Leased Asset Details
		leaseStart: formvalues.leaseStart,
		leaseTerm: formvalues.leaseTerm,
		leaseEnd:  formvalues.leaseEnd,
		leaseDollarBuyoutAmount: formvalues.leaseDollarBuyoutAmount,
		leaseMonthlyPayment: formvalues.leaseMonthlyPayment,
		leaseYearlyPayment: formvalues.leaseYearlyPayment,
		leaseTotalPayment: formvalues.leaseTotalPayment,
		isLegacyLease: formvalues.isLegacyLease
	}
	UnsyncedAssets.push(saveAsset);
}

const assetCodingClass = createStore({
	key: 'Id',
	loadUrl: '/api/assetcodingclasses/',
	loadMode: 'raw',//needed for searching
	onBeforeSend: (method, ajaxOptions) => {
		ajaxOptions.xhrFields = { withCredentials: true };
	}
});

const assetType = createStore({
	key: 'Id',
	loadUrl: '/api/assettypes/',
	loadMode: 'raw',
	onBeforeSend: (method, ajaxOptions) => {
		ajaxOptions.xhrFields = { withCredentials: true };
	}
});

const uom = createStore({
	key: 'Id',
	loadUrl: '/api/unitofmeasures/',
	loadMode: 'raw',
	onBeforeSend: (method, ajaxOptions) => {
		ajaxOptions.xhrFields = { withCredentials: true };
	}
});

const roomType = createStore({
	key: 'Id',
	loadUrl: '/api/roomtypes/',
	loadMode: 'raw',
	onBeforeSend: (method, ajaxOptions) => {
		ajaxOptions.xhrFields = { withCredentials: true };
	}
});

const assetServiceLife = createStore({
	key: 'Id',
	loadUrl: '/api/assetentryservicelives/',
	loadMode: 'raw',
	onBeforeSend: (method, ajaxOptions) => {
		ajaxOptions.xhrFields = { withCredentials: true };
	}
});

const assetEdit = new CustomStore({
	key: 'id',
	load: (id) => {
		return fetch("/api/assets/" + id, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(result => result.json())
			.catch(handleErrors);
	}
});

function handleErrors(response) {
	if (!response.ok) {
		alert("Editing items is not available while offline.");
		throw Error(response.statusText);
	}
	return response;
}

// Using the current minium ID value, generate a new negative ID for Offline saving
function generateNewOfflineId(currentMinimumId) {
	let newId;
	if (Number.isInteger(currentMinimumId)) {
		if (currentMinimumId > 0) {
			newId = -1;
		}
		else {
			newId = currentMinimumId - 1;
		}
	}
	else {
		newId = -1;
	}
	return newId;
}

async function addRoomAsync(values) {
	var jsonString = JSON.stringify(values);
	const response = await fetch('/api/studyrooms/', {
		method: "POST",
		body: jsonString,
		headers: {
			'Content-Type': 'application/json'
		}
	}).then((response) => response.json())
		.then((newRoom) => {
			return newRoom;
		});
	// .then(handleErrors);

	const syncRoomLookup = async () => {
		const a = await response;
		studyRoomsLookup.push({ 'id': a.id, 'name': a.name, 'studystructureid': a.studyStructureId });
		return a.id;
	};

	return syncRoomLookup();
}

async function addAssetAsync(formValues) {
	var jsonString = JSON.stringify(formValues);
	//var fileName = "assets.json"
	//const assetBlob = new Blob([jsonString],
	//	{
	//		type: 'application/json',
	//		name: fileName
	//	});

	//saveAs(assetBlob, fileName);

	//console.log("jsonString", jsonString);
	if (formValues.EditAssetId == "0" || formValues.Id == 0) {
		// New Asset
		const response = await fetch('/api/assets/', {
			method: "POST",
			body: jsonString,
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((response) => {
			if (response.status >= 200 && response.status < 300) {
				const dateTime = getCurrentDateTime();

				notify({
					message: 'You successfully added the asset ' + formValues.AssetSubTypeName + ' on ' + dateTime,
					position: {
						my: 'center top',
						at: 'center top'
					}
				}, 'success', 5000);
				return response.json();
			} else {
				notify({
					message: 'Asset was not added. Try again.',
					position: {
						my: 'center top',
						at: 'center top'
					}
				}, 'error', 5000);
			}
		})
			.then((newAsset) => {
				return newAsset;
				console.log('new Asset: ', newAsset);
			});

		const syncAssetLookup = async () => {
			const a = await response;
			if (response) {
				addToStudyAssetsLookup(a.id, a.assetSubTypeName, a.studyRoomId, formValues.CurrentUser.name, a.assetManufacturerName, a.description);
				return a.id;
			}
		};

		return syncAssetLookup();
	}
	else {
		// Editing Existing Asset
		let useId = formValues.EditAssetId;
		if (formValues.Id > 0) {
			useId = formValues.Id;
		}
		const response = await fetch('/api/assets/' + useId, {
			method: 'PUT',
			body: jsonString,
			headers: {
				'Content-Type': 'application/json',// Remove on PUT because response is blank
				'Authorization': "Bearer " + authService.getAccessToken()
			}
		}).then(result => {
			if (result.status >= 200 && result.status < 300) {

				const dateTime = getCurrentDateTime();

				notify({
					message: 'You successfully edited the asset ' + formValues.AssetSubTypeName + ' on ' + dateTime,
					position: {
						my: 'center top',
						at: 'center top'
					}
				}, 'success', 5000);

				return result;
			} else {
				notify({
					message: 'Asset was not edited. Try again.',
					position: {
						my: 'center top',
						at: 'center top'
					}
				}, 'error', 5000);
			}
		})
		.catch(error => {
			alert("Editing items is not available while offline.");
			console.error('Error:', error);
		});

		const syncAssetLookup = async () => {
			const a = await response;
			//console.log(formValues.EditAssetId, formValues.AssetSubTypeName, formValues.StudyRoomId, formValues.CurrentUser.name);
			if (response) {
				addToStudyAssetsLookup(formValues.EditAssetId,
					formValues.AssetSubTypeName,
					formValues.StudyRoomId,
					formValues.CurrentUser.name,
					formValues.AssetManufacturerName,
					formValues.Description);

				return a;
			}
		};

		return syncAssetLookup();

	}
}

function getCurrentDateTime() {
	const today = new Date();
	const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
	const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
	return date + ' ' + time;
}

export default CRSEntry;
