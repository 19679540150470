import CustomStore from "devextreme/data/custom_store";
import { ClubList } from "../types/clubList";

const KEY_VALUE: keyof ClubList = "id";

type KeyType = ClubList[typeof KEY_VALUE];

const handleErrors = (response: Response) => {
    if (!response.ok) throw Error(response.statusText);
    return response;
};

const CLUBS_LIST_URL = "/api/clublists/";

const NEW_CLUBS_LIST_URL = CLUBS_LIST_URL + "uploadnewclubsonly/";

export const newClubListOnlyStore = new CustomStore({
    loadMode: "raw",
    key: KEY_VALUE,
    byKey: async (key) =>
        await fetch(CLUBS_LIST_URL + key, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(handleErrors)
            .then((result) => result.json()),
    load: async () =>
        await fetch(NEW_CLUBS_LIST_URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(handleErrors)
            .then((result) => result.json()),
});
