import CustomStore from "devextreme/data/custom_store";
import authService from "../components/api-authorization/AuthorizeService";
import { AssetServiceLife } from "../types/assetServiceLife";

const KEY_VALUE: keyof AssetServiceLife = "id";

type KeyType = AssetServiceLife[typeof KEY_VALUE];

const handleErrors = (response: Response) => {
    if (!response.ok) throw Error(response.statusText);
    return response;
};

const URL = '/api/assetentryservicelives/';

const token = authService.getAccessToken();

const loadAsync = async (key?: KeyType) =>
    await fetch(key ? URL + key : URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
        },
    })
        .then(handleErrors)
        .then((result) => result.json());

export const assetEntryServiceLifeStore = new CustomStore({
    loadMode: 'raw',
    key: KEY_VALUE,
    byKey: async (key) => await loadAsync(key),
    load: async () => await loadAsync(),
});
