import { exportDataGrid } from "devextreme/excel_exporter";
import dxDataGrid from "devextreme/ui/data_grid";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { markAssetsAsExportedAsync } from "../api/markAssetsAsExportedAsync";
import { Asset } from "../types/asset";

export const exportAssetGridAsync = async(e: { component?: dxDataGrid; cancel?: boolean }, exportFileName: string) => {
    e.component?.beginUpdate();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Study Data");
  
    const exportedAssetIds: number[] = [];

    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: ({ gridCell, excelCell }) => {
            var e = gridCell;
            if (!e) {
                return;
            }

            const assetData = e.data as Asset

            if (assetData && !exportedAssetIds.includes(assetData.id)) {
                exportedAssetIds.push(assetData.id);
            }

            if (
                e.rowType === "data" &&
                e.column?.dataField === "isLeased"
            ) {
                if (e.value === "true") {
                    excelCell.value = "Y";
                } else {
                    excelCell.value = "";
                }
            }
        },
    })
        .then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], {
                        type: "application/octet-stream",
                    }),
                    exportFileName + ".xlsx"
                );
            });
        })
        .then(async()=> {
            await markAssetsAsExportedAsync(exportedAssetIds)
                
            e.component?.endUpdate();
        });

    e.cancel = true;
};