import React from "react";
import { Container } from "reactstrap";

export const Home = () => {
    return (
        <Container>
            <div>
                <h1>CRS Study Tool</h1>
                <p>
                    This tool developed by Club Benchmarking is used by the CRS
                    team for data collection. Inside the{" "}
                    <strong>CRS Study Tool</strong> application, there are
                    numerous displays:
                </p>
                <ul>
                    <li>
                        <strong>Home</strong>: Summary of the application that
                        works as a dashboard.
                    </li>
                    <li>
                        <strong>Asset Entry</strong>: An entry tool for data
                        collection.
                    </li>
                    <li>
                        <strong>Study Setup</strong>: Provides a registration
                        display for setting up a new Study and see Study details
                    </li>
                    <li>
                        <strong>Structures & Rooms</strong>:
                    </li>
                    <ul>
                        <li>
                            <strong>Study Structures</strong>: Study Structures
                            summary\entry display
                        </li>
                        <li>
                            <strong>Study Rooms</strong>: Study Rooms
                            summary\entry display
                        </li>
                    </ul>
                    <li>
                        <strong>Asset Summaries</strong>:{" "}
                    </li>
                    <ul>
                        <li>
                            <strong>Asset Summary for Export</strong>: Provides
                            a summary display for Assets for export.
                        </li>
                        <li>
                            <strong>Asset Summary Drilldown</strong>: Provides a
                            summary display for Assets.
                        </li>
                    </ul>
                    <li>
                        <strong>Lookups</strong>: The following lookups are
                        provided in the tool.
                    </li>
                    <ul>
                        <li>
                            <strong>
                                Asset Coding Class, Asset Group, Asset Service
                                Life, Asset Type, Room Type, Structure/Location
                                Type, Unit of Measure
                            </strong>
                        </li>
                    </ul>
                    <li>
                        <strong>Profile</strong>: Provides a portal for the user
                        to change their email, change their password and manage
                        other profile related actions.
                    </li>
                </ul>
            </div>
        </Container>
    );
};
