import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, List } from "devextreme-react";
import { Item } from "devextreme-react/box";
import React, { useState } from "react";
import { AssetSummary } from "../types/assetSummary";
import "./summaryPanel.css";

interface AssetEntryPanelSummaryProps {
    assetCountThisRoom: number;
    dbSyncStatus: string;
    assetsThisRoom: AssetSummary[];
    unsyncedRoomCount: number;
    unsyncedAssetCount: number;
    editingItemId: number;
    handleDBSyncButtonClicked: () => void;
    updateStatesAfterSync: () => void;
    editPanelItemClicked: (editId: number) => void;
}

const CLASSNAMES = {
    PANEL_OPEN: "panelOpened",
    PANEL_CLOSED: "panelClosed",
    SHOW: "showContent",
    HIDE: "hideContent",
    UNSYNCED: "unsynced",
};

const SYNC_STATUS = {
    NOT_SYNCED: "NOT SYNCED",
};

const assetListKeyExpr: keyof AssetSummary = "id";
const assetListSearchExpr: (keyof AssetSummary)[] = [
    "name",
    "description",
];

export const AssetEntryPanelSummary = ({
    assetCountThisRoom,
    dbSyncStatus,
    assetsThisRoom,
    unsyncedRoomCount,
    unsyncedAssetCount,
    editingItemId,
    handleDBSyncButtonClicked,
    updateStatesAfterSync,
    editPanelItemClicked,
}: AssetEntryPanelSummaryProps) => {
    const [panelOpen, setPanelOpen] = useState(true);

    const togglePanelOpen = () => {
        setPanelOpen((isOpen) => !isOpen);
    };

    const PanelItem = (item: AssetSummary) => {
        const { id, description, name } = item;

        const descriptionText = description
            ? description.length > 20
                ? description.slice(0, 20) + "..."
                : description
            : "empty description";

        const isUnsyncedItem = id <= 0;

        return (
            <>
                <span className="assetName">{name}</span>
                <span className="description">{" - "+ descriptionText}</span>
                {isUnsyncedItem && (
                    <>
                        <span>{" - "}</span>
                        <span className={CLASSNAMES.UNSYNCED}>{"UNSYNCED"}</span>
                    </>
                )}
                <span>{" - "}</span>
                <FontAwesomeIcon className="editIcon" icon="edit" />
            </>
        );
    };

    const isUnsynced = dbSyncStatus === SYNC_STATUS.NOT_SYNCED;

    const showRefreshButton = assetsThisRoom.some((asset) => asset.id < 0);

    const handlePanelItemClick = (e: { itemData?: AssetSummary }) => {
        const id = e.itemData?.id;
        if (id) {
            editPanelItemClicked(id);
        }
    };

    return (
        <div
            className={`summaryPanel ${
                panelOpen ? CLASSNAMES.PANEL_OPEN : CLASSNAMES.PANEL_CLOSED
            }`}
        >
            <FontAwesomeIcon
                cursor="pointer"
                title={`${panelOpen ? "Close" : "Open"} Summary Panel`}
                icon={panelOpen ? "angle-double-right" : "angle-double-left"}
                onClick={togglePanelOpen}
            />
            <div className={`panelContent ${panelOpen ? CLASSNAMES.SHOW : CLASSNAMES.HIDE}`}>
                <p>Room Asset Count: {assetCountThisRoom}</p>
                <p>
                    DB Sync Status:{" "}
                    {
                        <span
                            className={
                                isUnsynced ? CLASSNAMES.UNSYNCED : undefined
                            }
                        >
                            {dbSyncStatus}
                        </span>
                    }
                </p>
                {isUnsynced ? (
                    <p>
                        Unsynced Rooms:{" "}
                        <span className={CLASSNAMES.UNSYNCED}>
                            {unsyncedRoomCount}
                        </span>
                        &nbsp; Unsynced Assets:{" "}
                        <span className={CLASSNAMES.UNSYNCED}>
                            {unsyncedAssetCount}
                        </span>
                    </p>
                ) : (
                    <p>No Unsynced Items</p>
                )}
                <Box
                    direction="row"
                    align="center"
                >
                    <Item baseSize={"auto"} visible={isUnsynced}>
                        <Button
                            type="danger"
                            className={'online'}
                            onClick={handleDBSyncButtonClicked}
                        >
                            Sync With Database
                        </Button>
                    </Item>
                    <Item baseSize={"auto"} visible={!isUnsynced && showRefreshButton}>
                            <Button
                                type="default"
                                onClick={updateStatesAfterSync}
                            >
                                Refresh Display
                            </Button>
                        </Item>
                </Box>
                {assetsThisRoom.length > 0 && (
                    <>
                        <div>Assets:</div>
                        <List
                            searchEnabled={true}
                            searchExpr={assetListSearchExpr
                            }
                            dataSource={assetsThisRoom}
                            itemRender={PanelItem}
                            onItemClick={handlePanelItemClick}
                            keyExpr={assetListKeyExpr}
                            selectedItemKeys={[editingItemId]}
                            pageLoadMode="scrollBottom"
                        />
                    </>
                )}
            </div>
        </div>
    );
};
export default AssetEntryPanelSummary;
