import { Study } from "../types/study";
import { createDataFields } from "../utility functions/createDataFields";

export const STUDY_DATA_FIELDS = createDataFields<Study>()({
    ID: "id",
   ENTITY_ID: "entityId",
   CLUB_NAME: "clubName",
   NAME: "name",
   CLIENT_NAME: "clientName",
   CLIENT_SALUTATION: "clientSalutation",
   CLIENT_TITLE: "clientTitle",
   COMPANY_ID: "companyId",
   YEAR: "year",
   DATE_OF_EXECUTIVE_REPORT: "dateOfExecutiveReport",
   CB_REPRESENTATIVE: "cB_Representative",
   CB_TITLE_OF_REPRESENTATIVE: "cB_TitleOfRepresentative",
   FILE_NAME: "fileName",
   DATE_CREATED: "dateCreated",
   CREATED_BY: "createdBy",
   DATE_MODIFIED: "dateModified",
   MODIFIED_BY: "modifiedBy",
   COMPASS_PUBLISHED_BY: "compassPublishBy",
   COMPASS_PUBLISH_DATE: "compassPublishDate",
   STUDY_CAMPUS: "studyCampus",
   BUDGETARY_CONTINGENCY_SALES: "budgetaryContingencySales"
})