import { NumberBox } from "devextreme-react";
import { INumberBoxOptions } from "devextreme-react/number-box";
import DataSource from "devextreme/data/data_source";
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { autoAssetStore } from "../stores/autoAssetStore";
import { ValidKey } from "../types/ValidKey";
import { Asset } from "../types/asset";
import { OmitStrict } from "../types/omitStrict";
import { getUpdatedAssetFilterValue } from "../utility functions/getUpdatedAssetFilter";

interface AssetAutoNumberBoxProps
    extends OmitStrict<
        INumberBoxOptions,
        "defaultValue" | "onValueChanged" | "step"
    > {
    valueExpr: string & ValidKey<Asset, number | null>;
    filterByManufacturerValue?: string;
    filterBySubTypeValue?: string;
    filterByDescriptionValue?: string;
    filterByModelNameValue?: string;
    onValueChanged: (newValue?: number) => void;
    overrideValue: (newValue?: number) => void;
    children: ReactNode;
}

const sortByField: keyof Asset = "dateModified";

/*
  The purpose of this Component is to AutoPopulate the numberbox based on 
  other selected values on an Asset. Only when ALL of the values for the 
  filterBy fields have been selected will the NumberBox update with the
  suggested value.
*/
export const AssetAutoNumberBox = ({
    valueExpr,
    filterByManufacturerValue,
    filterBySubTypeValue,
    filterByDescriptionValue,
    filterByModelNameValue,
    overrideValue,
    onValueChanged,
    children,
    ...rest
}: AssetAutoNumberBoxProps) => {
    const [suggestedValue, setSuggestedValue] = useState<number | undefined>(
        undefined
    );
    const dataSource = useMemo(
        () =>
            new DataSource({
                store: autoAssetStore,
                /*
        The select property is not required for this component to function, 
        however is used to only the object property needed by this component and no other properties.
        */
                select: valueExpr,
                //The sort field is needed in order to only return the moset recent value by dateModified.
                sort: [{ selector: sortByField, desc: true }],
                //The paginate and pageSize properties make sure that we only return a single record.
                paginate: true,
                pageSize: 1,
            }),
        [valueExpr]
    );

    const handleFilterValueChanges = useCallback(async () => {
        let unitValueToSet: number | null | undefined = null;

        if (
            filterByManufacturerValue &&
            //filterByDescriptionValue &&  FilterByDescription is optional and therefore we don't check for a value.
            filterByModelNameValue &&
            filterBySubTypeValue
        ) {
            const filter = getUpdatedAssetFilterValue(
                filterByManufacturerValue,
                filterBySubTypeValue,
                filterByDescriptionValue,
                filterByModelNameValue
            );

            if (filter) {
                dataSource.filter(filter);

                const filteredAssets: Array<Pick<Asset, typeof valueExpr>> =
                    await dataSource.load();

                if (filteredAssets.length > 0) {
                    unitValueToSet = filteredAssets[0][valueExpr];
                }
            }
        }

        overrideValue(unitValueToSet || undefined);
    }, [
        dataSource,
        filterByDescriptionValue,
        filterByManufacturerValue,
        filterByModelNameValue,
        filterBySubTypeValue,
        overrideValue,
        valueExpr,
    ]);

    /*
    The side Effect that should occur is that only when ALL of the filterBy
    fields have been selected, the dataSource should return the value
    associated with the valueExpr on the Asset and populate the value automatically.
    */
    useEffect(() => {
        handleFilterValueChanges();
    }, [handleFilterValueChanges]);

    const handleValueChange = (e: { value?: number }) => {
        onValueChanged(e.value);
    };

    return (
        <NumberBox onValueChanged={handleValueChange} step={0} {...rest}>
            {children}
        </NumberBox>
    );
};
