import CustomStore from "devextreme/data/custom_store";
import authService from "../components/api-authorization/AuthorizeService";
import { Inflation } from "../types/inflation";

const KEY_VALUE: keyof Inflation = "id";

type KeyType = Inflation[typeof KEY_VALUE];

const handleErrors = (response: Response) => {
    if (!response.ok) throw Error(response.statusText);
    return response;
};

export const INFLATION_URL = "/api/inflation/";

const loadAsync = async (key?: KeyType) =>
    await fetch(key ? INFLATION_URL + key : INFLATION_URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
        },
    })
        .then(handleErrors)
        .then((result) => result.json());

const token = authService.getAccessToken();

export const inflationStore = new CustomStore({
        loadMode: 'raw',
        key: KEY_VALUE,
        byKey: async (key) => await loadAsync(key),
        load: async () => await loadAsync(),    
        update: async(key, values) => {
            var jsonString = JSON.stringify(values);
            return await fetch(INFLATION_URL + key, {
                method: "PUT",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + authService.getAccessToken()
                }
            }).then(handleErrors);
        },
    })