import React, { useState } from "react";

import DataGrid, {
    Column,
    Editing,
    FormItem,
    HeaderFilter,
    Lookup,
    Pager,
    PatternRule,
    RequiredRule,
    SearchPanel,
} from "devextreme-react/data-grid";
import { structureLocationTypeStore } from "../stores/structureLocationTypeStore";
import { studyStructureStore } from "../stores/studyStructureStore";
import { ToolbarPreparingEventingInternal } from "../types/toolbarPreparingEventInternal";

const allowedPageSizes = [10, 20, 50, 100];

export const StudyStructure = () => {
    const [copyColumnsEnabled, setCopyColumnsEnabled] = useState(false);

    const onToolbarPreparing = (e: ToolbarPreparingEventingInternal) => {
        e.toolbarOptions?.items?.unshift(
            {
                location: "before",
                widget: "dxButton",
                options: {
                    text: copyColumnsEnabled
                        ? "Show All Columns"
                        : "Show Excel Copy Columns",
                    onClick: toggleCopyColumnsEnabled,
                },
            }
        );
    };

    const toggleCopyColumnsEnabled = () => {
        setCopyColumnsEnabled((isEnabled) => !isEnabled);
    };

    return (
        <DataGrid
            dataSource={gridDataSource}
            showBorders={true}
            onToolbarPreparing={onToolbarPreparing}
        >
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page {0} of {1} ({2} Study Structures)"
            />
            <Editing
                mode="form"
                allowUpdating={!copyColumnsEnabled}
                allowAdding={!copyColumnsEnabled}
                allowDeleting={!copyColumnsEnabled}
            />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} />

            <Column
                dataField="id"
                caption="Id"
                width={50}
                allowEditing={false}
                visible={!copyColumnsEnabled}
            />

            <Column dataField="sortOrder">
                <RequiredRule message="Sort Order is required" />
                <PatternRule
                    pattern="^[0-9]+$"
                    message="Sort Order must be a number"
                />
            </Column>

            <Column dataField="name" />

            <Column dataField="structureLocationTypeName">
                <FormItem visible={false} />
            </Column>
            <Column
                dataField="structureLocationTypeId"
                caption="Structure/Location Type"
                visible={false}
            >
                <Lookup
                    dataSource={structureLocationTypeStore}
                    valueExpr="id"
                    displayExpr="name"
                />
            </Column>

            <Column
                dataField="createdBy"
                width={100}
                allowEditing={false}
                visible={!copyColumnsEnabled}
            />
            <Column
                dataField="dateModified"
                width={100}
                allowEditing={false}
                visible={!copyColumnsEnabled}
            />
            <Column
                dataField="modifiedBy"
                width={100}
                allowEditing={false}
                visible={!copyColumnsEnabled}
            />
        </DataGrid>
    );
};

const gridDataSource = {
    store: studyStructureStore,
}

export default StudyStructure;
