import React, { useState } from "react";

import { CheckBox } from "devextreme-react";
import DataGrid, {
    Column,
    Editing,
    FormItem,
    Grouping,
    HeaderFilter,
    Lookup,
    Pager,
    SearchPanel,
} from "devextreme-react/data-grid";
import { roomTypeStore } from "../stores/roomTypeStore";
import { studyRoomStore } from "../stores/studyRoomStore";
import { studyStructureStore } from "../stores/studyStructureStore";

const allowedPageSizes = [10, 20, 50, 100];

const StudyRoom = () => {
    const [autoExpandAll, setAutoExpandAll] = useState(false);

    const toggleAutoExpandAll = () => {
        setAutoExpandAll((isExpanded) => !isExpanded);
    };
    
    return (
        <>
            <DataGrid dataSource={gridDataSource} showBorders={true}>
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} Study Rooms)"
                />
                <Editing
                    mode="form"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}
                />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />

                <Grouping autoExpandAll={autoExpandAll} />

                <Column
                    dataField="id"
                    caption="Id"
                    width={50}
                    allowEditing={false}
                    visible={true}
                />

                <Column dataField="studyStructureName" groupIndex={0}>
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField="studyStructureId"
                    caption="Structure/Location"
                    visible={false}
                >
                    <Lookup
                        dataSource={studyStructureStore}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>

                <Column dataField="name" />

                <Column dataField="roomTypeName">
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField="roomTypeId"
                    caption="Room Type"
                    visible={false}
                >
                    <Lookup
                        dataSource={roomTypeStore}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>

                <Column
                    dataField="createdBy"
                    width={100}
                    allowEditing={false}
                    visible={true}
                />
                <Column
                    dataField="dateModified"
                    width={100}
                    allowEditing={false}
                    visible={true}
                />
                <Column
                    dataField="modifiedBy"
                    width={100}
                    allowEditing={false}
                    visible={true}
                />
            </DataGrid>
            <div className="options">
                <div className="option">
                    <CheckBox
                        text="Expand All Structures"
                        onValueChanged={toggleAutoExpandAll}
                    />
                </div>
            </div>
        </>
    );
};

const gridDataSource = {
    store: studyRoomStore,
}

export default StudyRoom;
