import { StudyCampus } from "../types/studyCampus";
import { createDataFields } from "../utility functions/createDataFields";

export const STUDY_CAMPUS_DATA_FIELDS = createDataFields<StudyCampus>()({
    ID: "id",
    STUDY_ID: "studyId",
    NAME: "name",
    STREET: "street",
    ZIPCODE: "zipCode",
    CITY: "city",
    STATE_PROVINCE: "stateProvince",
    COUNTRY: "country",
    DATE_CREATED: "dateCreated",
    CREATED_BY: "createdBy",
    DATE_MODIFIED: "dateModified",
    MODIFIED_BY: "modifiedBy"
})