import CustomStore from "devextreme/data/custom_store";
import authService from "../components/api-authorization/AuthorizeService";
import { Asset } from "../types/asset";

const KEY_VALUE: keyof Asset = "id";

const handleErrors = (response: Response) => {
    if (!response.ok) throw Error(response.statusText);
    return response;
};

export const ASSETS_BULK_URL = "/api/assetsbulk/";

const token = authService.getAccessToken();

export type InsertBulkAssetParam = Array<Pick<Asset, "id">>

export const assetsBulkStore = new CustomStore({
    loadMode: "raw",
    key: KEY_VALUE,
    load: async () =>
        await fetch(ASSETS_BULK_URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(handleErrors)
            .then((result) => result.json())
});
