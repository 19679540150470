import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
    Column,
    Editing,
    FormItem,
    HeaderFilter,
    Lookup,
    Pager,
    PatternRule,
    RequiredRule,
    SearchPanel
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';

import 'whatwg-fetch';

import 'devextreme/data/array_store';
import { assetCodingClassStore } from '../stores/assetCodingClassStore';
import { assetGroupStore } from '../stores/assetGroupStore';

const allowedPageSizes = [10, 20, 50, 100];

const AssetCodingClass = () => {
        return (
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
            >
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} Asset Coding Classes)"
                />
                <Editing
                    mode="form"
                    allowUpdating={true} 
                >
                </Editing>
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Column dataField="id" caption="Id" width={50} allowEditing={false}/>
                <Column dataField="code" width={75}>
                    <RequiredRule message="Code is required" />
                </Column>
                <Column dataField="name">
                    <RequiredRule message="Name is required" />
                </Column>
                <Column dataField="assetGroupName" caption="Asset Group" width={125}>
                    <FormItem visible={false}/>
                </Column>
                <Column dataField="assetGroupId" caption="Asset Group" width={125} visible={false}>
                    <RequiredRule message="Asset Group is required" />
                    <Lookup dataSource={assetGroupStore} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="sortOrder" width={100}>
                    <RequiredRule message="Sort Order is required" />
                    <PatternRule
                        pattern="^[0-9]+$"
                        message="Sort Order must be a number"
                    />
                </Column>
                <Column dataField="createdBy" width={100} allowEditing={false}/>
                <Column dataField="dateModified" width={100} allowEditing={false}/>
                <Column dataField="modifiedBy" width={100} allowEditing={false}/>
            </DataGrid>
        );
    }

const gridDataSource = {
    store: assetCodingClassStore
}

export default AssetCodingClass;
